// Sidebar widget
// -------------------------

.sidebar-widget {
	margin-bottom: 40px;

	.tag {
		color: @text-color;
		border-color: @text-color;
		font-size: 14px;
	}

	.edu-tag {
		span {
			font-size: 14px;
		}
	}
}

.sidebar-widget__title {
	font-size: 20px;
	line-height: 2.05;
	margin: 0;
}

.sidebar-widget__list {
	.reset-list();
	margin-bottom: 25px;

	li {
		border-bottom: 1px solid @text-color;
		padding: 15px 0;
	}
}

// Supporters widget
// -------------------------

.supporters-widget__supporters {
	li {
		display: table;
		width: 100%;

		> div {
			display: table-cell;
			vertical-align: middle;
			text-align: left;

			+ div {
				text-align: right;
			}
		}
	}
}

.supporters-widget__date {
	display: block;
	font-size: 12px;

	.icon {
		.square(10px);
		margin-right: 2px;
		fill: @text-color;
	}
}

.supporters-widget__name {
	.avenir-black();
	display: block;
}

// Sponsors widget
// -------------------------

.sponsors-widget__sponsors {
	li {
		overflow: hidden;
	}
}

.sponsors-widget__image {
	.square(70px);
	margin-right: 15px;
	float: left;

	@media(min-width: @screen-md-min) {
		.square(50px);
	}

	@media(min-width: @screen-lg-min) {
		.square(70px);
	}
}

.sponsors-widget__info {
	overflow: hidden;
	.avenir-black();
	font-size: 14px;
}

.sponsors-widget__name {
	display: block;
	margin-bottom: 5px;

	@media(min-width: @screen-lg-min) {
		padding-right: 60px;
	}
}