.hamburger {
	padding: 15px;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, -webkit-filter;
	transition-property: opacity, filter;
	transition-property: opacity, filter, -webkit-filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
}

.hamburger-box {
	width: 22px;
	height: 13px;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
	width: 22px;
	height: 2px;
	background-color: #000;
	position: absolute;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
	content: "";
	display: block;
}

.hamburger-inner::before {
	top: -5px;
}

.hamburger-inner::after {
	bottom: -5px;
}

/*
 * Slider
 */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 5px;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-timing-function: ease;
    transition-duration: 0.2s; }
  .hamburger--slider .hamburger-inner::after {
    top: 10px; }

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 5px, 0) rotate(45deg);
          transform: translate3d(0, 5px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
            transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-90deg);
            transform: translate3d(0, -10px, 0) rotate(-90deg); }