// Fonts
// -------------------------

@font-face {font-family: 'AvenirLTStd-Heavy';src: url('../../fonts/312C59_0_0.eot');src: url('../../fonts/312C59_0_0.eot?#iefix') format('embedded-opentype'),url('../../fonts/312C59_0_0.woff2') format('woff2'),url('../../fonts/312C59_0_0.woff') format('woff'),url('../../fonts/312C59_0_0.ttf') format('truetype');}

@font-face {font-family: 'AvenirLTStd-Roman';src: url('../../fonts/312C59_1_0.eot');src: url('../../fonts/312C59_1_0.eot?#iefix') format('embedded-opentype'),url('../../fonts/312C59_1_0.woff2') format('woff2'),url('../../fonts/312C59_1_0.woff') format('woff'),url('../../fonts/312C59_1_0.ttf') format('truetype');}

@font-face {font-family: 'AvenirLTStd-Black';src: url('../../fonts/312C59_2_0.eot');src: url('../../fonts/312C59_2_0.eot?#iefix') format('embedded-opentype'),url('../../fonts/312C59_2_0.woff2') format('woff2'),url('../../fonts/312C59_2_0.woff') format('woff'),url('../../fonts/312C59_2_0.ttf') format('truetype');}

// Global elements
// -------------------------

html {
	-webkit-font-smoothing: antialiased;
}

body {
	overflow-x: hidden; // meh
	padding-top: @edu-header-height-m;

	@media(min-width: @screen-md-min) {
		padding-top: 0;
	}

	&.no-padding {
		padding-top: 0;
	}
}

html, body {
	height: 100%;
}

// Forms
// -------------------------

.svgmask {
	position: absolute;
	width: 0;
	height: 0;
	overflow: hidden;
}

// Forms
// -------------------------

.parsley-errors-list {
	display: none;
}

#parsley-id-9 {
    display: block;
    color: @torch-red;
    .reset-list();
}

.donation-details .parsley-errors-list {
	display: block;
	color: @torch-red;
	.reset-list();
}

.form-group {
	text-align: left;
	position: relative;

	label {
		.avenir-black();
		font-size: 10px;
		line-height: 2.4;
		text-transform: uppercase;
		text-align: left;
		font-weight: 400;
		margin: 0;

		span {
			.avenir-roman();
			margin-left: 8px;
		}
	}

	&.has-error {
		color: @torch-red;
		input {
			border-color: @torch-red;
		}
	}

	.error {
		display: block;
		color: @torch-red;
		.reset-list();
	}
}

.form-group--center {
	text-align: center;
}

.form-group--select {
	margin-bottom: 30px;

	label {
		padding-bottom: 5px;
	}
}

.form-group--image {
	label {
		padding-bottom: 5px;
	}
}

.form-group--video{
	margin-top: 40px;
	.videowrapper{
		position: relative;
		overflow: hidden;
		display: inline-block;
		button{
			cursor: pointer;
		}
		input{
			font-size: 100px;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
		}
	}
	.progresswrapper{
		display: none;
		.lds-ellipsis {
			display: inline-block;
			position: relative;
			width: 64px;
			height: 64px;
		}
		.lds-ellipsis div {
			position: absolute;
			top: 27px;
			width: 11px;
			height: 11px;
			border-radius: 50%;
			background: black;
			animation-timing-function: cubic-bezier(0, 1, 1, 0);
		}
		.lds-ellipsis div:nth-child(1) {
			left: 6px;
			animation: lds-ellipsis1 0.6s infinite;
		}
		.lds-ellipsis div:nth-child(2) {
			left: 6px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		.lds-ellipsis div:nth-child(3) {
			left: 26px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		.lds-ellipsis div:nth-child(4) {
			left: 45px;
			animation: lds-ellipsis3 0.6s infinite;
		}
		@keyframes lds-ellipsis1 {
			0% {
				transform: scale(0);
			}
			100% {
				transform: scale(1);
			}
		}
		@keyframes lds-ellipsis3 {
			0% {
				transform: scale(1);
			}
			100% {
				transform: scale(0);
			}
		}
		@keyframes lds-ellipsis2 {
			0% {
				transform: translate(0, 0);
			}
			100% {
				transform: translate(19px, 0);
			}
		}
	}
	.js-progress{
		display: block;
		font-size: 18px;
		color: @text-color;
		margin-bottom: 10px;
	}
	.uploaded_message{
		display: none;
	}
}

.form-group--video--uploading{
	button{
		pointer-events: none;
		filter: grayscale(100%);
	}
	.progresswrapper{
		display: block;
	}
}

.form-group--video--uploaded{
	.videowrapper,
	.progresswrapper{
		display: none;
	}
	.uploaded_message{
		display: block;
	}
}

.form-group--radios{
	margin-bottom: 20px;
	label:first-child{
		margin-bottom: 5px;
	}
	.radiobutton{
		margin-bottom: 11px;
	}
	[type="radio"]:checked,
	[type="radio"]:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	[type="radio"]:checked + label,
	[type="radio"]:not(:checked) + label
	{
		position: relative;
		padding-left: 40px;
		cursor: pointer;
		display: inline-block;
		font-size: 14px;
		font-style: normal;
		line-height: 1.63;
		color: #1a1919;
	}
	[type="radio"]:checked + label:before,
	[type="radio"]:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border: 2px solid #000;
		border-radius: 100%;
		background: #fff;
	}
	[type="radio"]:checked + label:after,
	[type="radio"]:not(:checked) + label:after {
		content: '';
		width: 12px;
		height: 12px;
		background: #000;
		position: absolute;
		top: 4px;
		left: 4px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	[type="radio"]:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	[type="radio"]:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.form-control {
	.placeholder(lighten(@text-color, 60%));
	font-size: 15px;
	line-height: 1.33;
	border: none;
	border-bottom: 2px solid @text-color;
	padding: 8px 0;
	height: auto;
	color: @text-color;
	border-radius: 0;
	box-shadow: none;
	//opacity: 0.5;
	transition: opacity .15s;
	margin-bottom: 20px;
	background: transparent;

	&:focus, &:active {
		border-color: @text-color;
		outline: 0;
		box-shadow: none;
		opacity: 1;
	}

	&[type="text"] {
		padding-right: 20px;
	}
	@media(min-width: @screen-sm-min) {
		font-size: 18px;
	}
}

.form-control--white{
	color: white;
	border-bottom: 2px solid white;
	&:focus, &:active {
		border-color: white;
	}
}

.help-text {
	font-size: 11px;
	line-height: 2.2;
	color: #888888;
	margin-bottom: 0;
	text-align: left;
}

.help-text--warning {
	font-size: 13px;
	line-height: 1.5;
	margin-bottom: 15px;
	color: @orange;
}

.help-text--invitation {
	line-height: 1.5;
	margin-bottom: 15px;
	color: @orange;
	font-size: 17px;
	border: 2px solid #c3c3c3;
	border-radius: 4px;
	padding: 15px;

	a {
		font-weight: bold;
	}
}

.form-control + .help-text, .parsley-errors-list + .help-text {
	margin: -15px 0 0 0;
}

.help-text--checkbox {
	margin-left: 30px;
}

.form-text {
	text-align: left;
}

.qr-image {
	.img-responsive();
	margin: 0 auto 10px auto;
}

// .image-input-wrapper.form-control {
// 	opacity: 1;
// }

.parsley-success {
	.form-control {
		border-color: @text-color;
		outline: 0;
		box-shadow: none;
		opacity: 1 !important;
	}

	&:after {
		content: url(../../images/valid_dark.svg);
		position: absolute;
		right: 0;
		top: 30px;
		display: block;
		width: 12px;
		height: 9px;
		//margin-top: 2px;
	}
}

.parsley-error& {
	label {
		color: @torch-red !important;
	}

	.form-control {
		.placeholder(@torch-red) !important;
		border-color: @torch-red !important;
		outline: 0;
		box-shadow: none;
		opacity: 1 !important;
		color: @torch-red;
	}

	.btn {
		border-color: @torch-red !important;
	}
}

.footer-form {
	.parsley-success {
		&:after {
			top: 35px;
		}
	}
}

// Set background color in parent
.textarea-label {
	//position: relative;
	//bottom: -18px;
	//left: 10px;
	//z-index: 10;
	padding-bottom: 5px;
	//background: transparent;
}

textarea.form-control {
	border: 2px solid @text-color;
	//border-radius: 8px;
	padding: 18px;
	font-size: 15px;
	line-height: 1.6;
}

.redactor-box {
	border: 2px solid @text-color;
	font-size: 15px;
	line-height: 1.6;
	.redactor-toolbar{
		border-bottom: 1px solid @text-color;
		box-shadow: none;
	}
	.redactor-toolbar li a:hover {
	    background-color: @orange;
	}
}



.parsley-success.form-group--number {

}

.parsley-success.form-group--textarea, .parsley-success.form-group--select {
	&:after {
		top: 0px;
		right: 0px;
	}
}

.form-group--nolabel, .form-group--image {
	&.parsley-success {
		&:after {
			top: 0;
		}
	}
}

.form-group--nolabel.form-group--image {
	&.parsley-success {
		&:after {
			top: -25px;
		}
	}
}

// .form-group--image {
// 	.form-control {
// 		border-color: @text-color;
// 		outline: 0;
// 		box-shadow: none;
// 		opacity: 1;
// 	}
// }

.form-group .form__checkbox {
	.avenir-heavy();
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.71;
	display: block;
	text-transform: none;

	+ .form__checkbox {
		margin-top: 14px;
	}

	input {
		display: none;

		&:checked {
			+ span {
				//background: @text-color;
				opacity: 1;
				background-image: url(../../images/valid_dark.svg);
				background-position: center center;
				background-repeat: no-repeat;
			}
		}
	}

	> span {
		.square(18px);
		border: 2px solid @text-color;
		background: #fff;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		top: -1px;
		margin-right: 10px;
		margin-left: 0;
		opacity: .2;
		transition: opacity .15s;
	}
}

.form--invert {
	label {
		color: #fff;
	}

	.form-control {
		.placeholder(#fff);
		color: #fff;
		border-color: #fff;
	}

	textarea.form-control {
		border-color: #fff;
	}

	.parsley-success {
		border-color: #fff;

		&:after {
			content: url(../../images/valid.svg);
		}
	}
}

.has-error {
	.form-control:focus, .form-control {
		border-color: @text-color;
		box-shadow: none;
	}

	.help-block {
		color: @torch-red;
	}
}

.password-error{
	display: block;
	font-size: 16px;
	color: @torch-red;
	margin-bottom: 15px;
}
