.modal {
	text-align: center;
	padding: 0!important;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -4px;
	}

	form {
		max-width: 337px;
		width: 100%;
		margin: 0 auto;
	}
}

.modal-body {
	padding: 25px 15px;

	@media(min-width: @screen-sm-min) {
		padding: 50px 15px;
	}
}

.modal-dialog {
	display: inline-block;
	text-align: left;
	vertical-align: middle;
}

.modal-content {
	border: none;
	text-align: center;
	box-shadow: none;
}

.modal__title {
	font-size: 28px;
	line-height: 1.2;
	letter-spacing: -1px;
	position: relative;
	margin: 0 auto;
	margin-bottom: 24px;
	padding-bottom: 10px;
	max-width: 300px;

	&:after {
		content: '';
		width: 60px;
		height: 5px;
		background-color: @text-color;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -30px;
	}

	@media(min-width: @screen-sm-min) {
		font-size: 35px;
	}
}

.modal__submit {
	.button-square();
	margin-top: 10px;
}

.modal__back-button {
	margin-top: 10px;
}