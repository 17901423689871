.crowdfunding-button {
    .button-rounded;
    background: #2BB667;

    &:hover,
    &:focus {
        background: darken(#2BB667, 10%);
        color: white;
    }
}

.form-group__notice {
    display: block;
    color: #A9A9A9;
    font-size: 12px;

    a {
        color: #A9A9A9;
        text-decoration: underline;
    }
}

.crowdfunding-center {
    text-align: center;
    padding: 0 20px;

    a {
        width: 100%;
    }
}

.crowdfunding-button--donate {
	margin: 0 auto 75px auto;
}

.crowdfunding_title {
    .avenir-black;
    margin: 0 0 25px;
    font-size: 20px;
}

.crowdfunding__link {
    .more-link;
    color: black;
    background: none;
    border: 0;

    svg {
        fill: black;
        transform: rotate(-90deg);
    }

    &--back {
        svg {
            transform: rotate(180deg);
        }
    }
}

.crowdfunding-picker__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
        margin-bottom: 10px;
    }

    @media(min-width: @screen-md-min) {
        display: block;

        label {
            margin-bottom: 0;
        }
    }
}

.crowdfunding-amounts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    @media(min-width: @screen-sm-min) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.crowdfunding-sharing {
    display: flex;
    justify-content: flex-end;
}

.crowdfunding-grid {

    @media(min-width: @screen-md-min) {
        display: grid;
        grid-template-columns: 242px 1fr 290px;
        grid-template-rows: 300px 1fr;
        gap: 0px 0px;
        grid-template-areas:
            "Header Header Sidebar"
            "Menu Content Sidebar";
        width: 100%;
        height: 100%;
    }

    @media(min-width: @screen-lg-min) {
		grid-template-columns: 292px 1fr 320px;
        grid-template-rows: 450px 1fr;
	}

    @media(min-width: @edu-desktop) {
        grid-template-columns: 292px 1fr 420px;
        grid-template-rows: 537px 1fr;
	}
}

.crowdfunding-header {
    grid-area: Header;
    height: 350px;

    @media(min-width: @screen-md-min) {
        height: auto;
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.crowdfunding-funnelheader {
    .header-menu {
        opacity: 1;
        visibility: visible;
    }
}

.crowdfunding-content {
    grid-area: Content;
}

.crowdfunding-menu {
    grid-area: Menu;
}

.crowdfunding-sidebar {
    grid-area: Sidebar;

    .action-stats {
        padding: 30px 0 35px;
    }
}

.crowdfunding__interim {
    margin: 0 30px 24px;
    font-size: 20px;
    text-align: left;
    .avenir-black;
}

.crowdfunding-menu-wrapper {
    padding: 30px;

    @media(min-width: @screen-md-min) {
        padding: 0 30px;
    }

    .sidebar-widget {
        padding: 0 20px;

        .edu-tag {
            span {
                white-space: nowrap;
            }
        }
    }
}

.crowdfunding-menu-options {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 20px;
}

.crowdfunding-donation-option {
    display: flex;
    align-items: center;
    column-gap: 20px;

    img {
        max-width: 100px;
    }
}

.crowdfunding-donation-option__price {
    padding: 8px 20px 6px;
    background: @orange;
    color: white;
    .avenir-black;
    border-radius: 7px;
    line-height: 1;
}

.donation-section--crowdfunding {
    max-width: 720px;

    .donation-details {
        @media(min-width: @screen-md-min) {
            padding-left: 175px;
            padding-right: 175px;
        }

        h3 {
            font-size: 18px;
            text-align: left;
            margin: 50px 0 20px 0;
        }
    }

    .form-group .form__checkbox {
        display: flex;
        line-height: 17px;

        span {
            flex-shrink: 0;
        }
    }
}

.donation-picker__amount--crowdfunding {
    position: relative;
    z-index: 1;
    margin-left: 37px!important;
    margin-right: 25px!important;
    font-size: 25px;

    span {
        border-left: 0;
        padding-left: 4px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            height: 35px;
            width: 35px;
            background-color: white;
            border: 2px solid black;
            border-radius: 7px;
            transform: translate(-15px, 5px) rotate(45deg);
            transition: background .15s, border .15s, color .3s;
            z-index: -1;
        }
    }

    > input:checked + span {
        background: @orange;
        border-color: @orange;

        &:before {
            background: @orange;
            border-color: @orange;
        }
    }
}

.donation-picker__custom-amount--crowdfunding {
    margin-left: 37px!important;
    margin-right: 25px!important;

    .donation-picker__wrapper,
    input[type="number"]{
        width: 110px;
    }

    input[type="number"] {
        border-left: 0;
        font-size: 25px;

        &:focus {
            + .donation-picker__euro {
                opacity: 1;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        height: 35px;
        width: 35px;
        background-color: white;
        border: 2px solid black;
        border-radius: 7px;
        transform: translate(-15px, 5px) rotate(45deg);
        transition: background .15s, border .15s, color .3s;
    }

    .donation-picker__euro {
        opacity: 0.35;
    }
}

.donation-section--crowdfunding {
    .parsley-errors-list {
        display: block;
        .reset-list();
        font-size: 13px;
        color: @torch-red;
        margin-top: -10px;
        margin-bottom: 10px;
    }
}

.crowdfunding-cta {
    padding: 50px 25px 0 25px;

    a {
        margin: 0;
    }
}

.crowdfunding-amount {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        margin-top: 20px;
        height: auto;
        display: none;
        @media(min-width: @screen-md-min) {
            display: block;
            width: 120px;
            height: 120px;
            object-fit: contain;
        }
    }

    p {
        margin: 0 0 20px;
        font-size: 16px;
        .avenir-black;
        
        @media(min-width: @screen-md-min) {
            width: 120px;
            margin: 10px 0;
        }
    }
}