.action-creator {
	margin-bottom: 30px;
	color: #fff;
	display: table;
	width: 100%;

	@media(min-width: @screen-sm-min) {
		margin-bottom: 50px;
	}
}

.action-creator__image-wrapper {
	display: table-cell;
	vertical-align: top;
	width: 40px;
}

.action-creator__image {
	.square(40px);
	border-radius: 50%;
	margin-right: 13px;
	display: inline-block;
	vertical-align: middle;
}

.action-creator__text-wrapper {
	display: table-cell;
	vertical-align: top;
}

.action-creator__text {
	font-size: 12px;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: 4px;
}

.action-creator__by {
	display: block;
	font-size: 12px;
	line-height: 0.92;
	margin-bottom: 2px;
}

.action-creator__creator {
	.avenir-black();
	display: block;
	font-size: 14px;
	line-height: 1.1;
}

.action-creator--card {
	margin-bottom: 0;
	color: @text-color;
}