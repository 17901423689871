// Projects section
// -------------------------

// .cssmask .projects-section__mask {
// 	height: 80px;
// 	position: absolute;
// 	top: -80px;
// 	width: 100%;
	
// 	rect {
// 		mask: url(#projectmask);
// 	}
// }

.projects-section__mask {
	height: 80px;
	position: absolute;
	top: -80px;
	width: 100%;
	display: none;
}

// .cssmask .projects-section {
// 	padding: 40px 0;
// 	padding-bottom: 80px;
// 	position: relative;

// 	+ .projects-section {
// 		.projects-section__mask {
// 			display: block;
// 		}
// 	}

// 	@media(min-width: @screen-md-min) {
// 		padding: 80px 0;
// 		padding-bottom: 160px;
// 	}
// }

.projects-section {
	padding: 40px 0;
	//padding-bottom: 80px;
	position: relative;

	.lead-text {
		p {
			margin-bottom: 30px;
		}
	}

	@media(min-width: @screen-sm-min) {
		padding: 80px 0;
		padding-bottom: 160px;
		background-image: url(../../pngs/maskmetschaduw4.png);
		background-position: left bottom -450px;
		background-repeat: repeat-x;

		+ .projects-section {
			.projects-section__mask {
				display: block;
			}
		}

		&:last-child {
			background-image: none;
		}
	}

	// @media(min-width: @screen-sm-min) {
	// 	padding: 80px 0;
	// 	padding-bottom: 160px;
	// }
}

.projects-section__text {
	.make-sm-column(3.9);
}

.projects-section__title, .home-projects__title {
	.title--biggest();
	padding-bottom: 12px;
	position: relative;
	color:#fff;
	margin-bottom: 35px;

	&:after {
		content: '';
		width: 70px;
		height: 8px;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

.projects-section__cta {
	&:hover, &:focus, &:active {
		color: #FF5913;
	}
}

.projects-section__projects {
	.make-sm-column(8.1);

	.card {
		max-width: 668px;
		width: 100%;

		@media(min-width: @screen-sm-min) {
			float: right;
		}

		&:nth-child(even) {
			.card__text {
				
			}
		}
	}
}

.projects-section--flipped {
	.projects-section__text {
		.make-sm-column-push(8.1);
	}

	.projects-section__projects {
		.make-sm-column-pull(3.9);

		.card {
			@media(min-width: @screen-sm-min) {
				float: left;
			}
		}
	}
}