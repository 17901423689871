.share-buttons {
	.reset-list();
	overflow: hidden;

	li {
		float: left;

		+ li {
			margin-left: 10px;
		}
	}

	a {
		display: inline-block;
		width: 100%;
		height: 100%;

		// &:hover {
		// 	.icon--default {
		// 		display: none;
		// 	}

		// 	.icon--hover {
		// 		display: block;
		// 	}
		// }
	}

	.share-icon {
		//.square(26px); // temp
		//border-radius: 50%;
		//border: 1px solid #fff;
		//background: transparent;
	}

	.icon {
		.square(26px);
	}

	.icon-share-right {
		width: 30px;
		height: 26px;
	}
}

.no-touchevents {
	.share-buttons {
		a {
			&:hover {
				.icon--default {
					display: none;
				}

				.icon--hover {
					display: block;
				}
			}
		}
	}
}

.share-buttons--single-update {
	li {
		float: none;
		display: block;
		margin-bottom: 10px;

		+ li {
			margin-left: 0;
		}
	}

	.share-icon {
		.square(34px); // temp
	}

	.icon {
		.square(34px);
	}

	.icon-share-bottom, .share-icon--email {
		width: 34px;
		height: 39px;
	}
}