// General profile styles
// -------------------------

.profile-content {
	background-color: @blue;
	color: #fff;
}

// Left column
// -------------------------

.profile-header {
	background: @deep-sapphire;
	padding-top: @edu-p / 2;
	padding-bottom: @edu-p / 2;
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;
	text-align: center;
	margin-bottom: 10px;

	@media(min-width: @screen-md-min) {
		margin-top: -@edu-p-v;
		padding-top: 40px;
		padding-bottom: 40px;
	}

	@media(min-width: @screen-lg-min) {
		padding-left: @edu-p;
		padding-right: @edu-p;
	}
}

.profile-header__image {
	.square(102px);
	margin: 0 auto;
	margin-bottom: 20px;
	border-radius: 50%;
}

.profile-header__name {
	.avenir-black();
	font-size: 17px;
	color: #fff;
}

// Center column
// -------------------------

// Edit profile
.profile__submit {
	margin-top: 20px;

	@media(max-width: @screen-xs-max) {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

}

// My actions
.profile__actions {
	.row {
		margin-left: -10px;
		margin-right: -10px;
	}

	.card {
		.make-md-column(6);
		max-width: 272px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.profile__section {
	margin-bottom: 40px;
}

.profile__no-actions{
	@media(min-width: @screen-md-min) {
		width: 350px;
	}
}

.profile__no-actions-text{
	font-size: 18px;
	line-height: 1.33;
	margin-bottom: 50px;
	@media(min-width: @screen-sm-min) {
		font-size: 21px;
		line-height: 1.48;
	}
}