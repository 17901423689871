.search-content {
	background: @blue;
	padding: 40px 0;

	@media(min-width: @screen-md-min) {
		padding: 80px 0;
	}
}

html.cssmask .search-content {
	@media(min-width: @screen-md-min) {
		padding-bottom: 160px;
	}
}

.search-form {
	padding: 0 0 40px 0;
	max-width: 490px;
	width: 100%;
	margin: 0 auto;
	position: relative;

	@media(min-width: @screen-sm-min) {
		padding-top: 20px;
		padding-bottom: 100px
	}

	label {
		.avenir-black();
		color: #fff;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 0.5px;
		font-weight: normal;
	}

	input[type="text"] {
		.placeholder(#fff);
		width: 100%;
		font-size: 28px;
		line-height: 0.86;
		border: none;
		padding: 9px 0;
		padding-right: 30px;
		color: #fff;
		background: transparent;
		transition: opacity .15s;
		border-bottom: 3px solid #fff;

		&:focus, &:active {
			outline: 0;
			opacity: 1;
		}
	}

	button {
		border: none;
		background: transparent;
		padding: 0;
		position: absolute;
		right: 0;    
		top: 40px;

		@media(min-width: @screen-sm-min) {
			top: 60px;
		}

		.icon {
			width: 19px;
			height: 19.7px;
		}
	}
}

.search-content-wrapper {
	@media(min-width: @screen-sm-min) {
		padding-bottom: 80px;
		background-image: url(../../pngs/maskmetschaduw4.png);
		background-position: left bottom -450px;
		background-repeat: repeat-x;
	}
}

.search-content__mask {
	height: 80px;
	position: absolute;
	top: -80px;
	width: 100%;
	display: none;

	@media(min-width: @screen-sm-min) {
		display: block;
	}
}

.search-results-wrapper {
	position: relative;
	// @media(min-width: @screen-sm-min) {
	// 	position: relative;

	// 	&:before {
	// 		content: '';
	// 		width: 100%;
	// 		height: 279px;
	// 		background-image: url(../../images/pngs/blauw.png);
	// 		background-repeat: repeat-x;
	// 		background-position: center top;
	// 		background-size: 1280px 279px;
	// 		position: absolute;
	// 		top: -90px;
	// 		left: 0;
	// 		pointer-events: none;
	// 	}
	// }
}

.search-results {
	.make-row();
	margin-left: -10px;
	margin-right: -10px;
	position: relative;

	@media(min-width: @screen-sm-min) {
		padding-top: 30px;
	}

	.card {
		.make-md-column(3);
		padding-left: 10px;
		padding-right: 10px;
	}
}

.search-results__meta {
	text-align: center;
	color: #fff;
	margin-bottom: 40px;
}