.action-info-footer {
	text-align: center;

	> span {
		.avenir-black();
		font-size: 20px;
		line-height: 2.05;
		color: #fff;
		display: block;

		@media(min-width: @screen-sm-min) {
			margin-right: 24px;
			display: inline;
		}
	}

	@media(min-width: @screen-sm-min) {
		text-align: left;
	}
}

.action-info-footer__button {
	//.button-rounded();
}