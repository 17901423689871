.comment-form {
	.textarea-label {
		//background: @yellow-sea;
	}
}

.comment-form__submit {
	//.more-link();
	background: transparent;
	border: none;
	padding: 0;
	margin-top: 10px;
}