.action-stats {
	background: @wild-sand;
	padding: 44px 0;
	text-align: center;
	margin-bottom: 40px;
}

.action-stats--mobile {
	margin-left: -@edu-p / 2;
	margin-right: -@edu-p / 2;
}

.action-stats__amounts {
	.avenir-black();
	display: table;
	width: 100%;
}

.action-stats__current-amount, .action-stats__target-amount {
	display: table-cell;
	vertical-align: baseline;
	width: 50%;
}

.action-stats__current-amount {
	font-size: 30px;
	line-height: 1.37;
}

.action-stats__target-amount {
	font-size: 20px;
	line-height: 2.05;
}

.action-stats__meta {
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 0.5px;
	text-align: center;
	margin-top: 50px;
	//margin-bottom: 25px;

	.icon {
		fill: @text-color;
		position: relative;
		top: 1px;
		left: -2px;
	}

	.icon-supporters {
		width: 11px;
		height: 9.6px;
	}

 	> div {
		display: inline-block;
		margin: 0 8px;

 		span {
			.avenir-black();
 		}
 	}
}

.action-stats__supporters {

}

.action-stats__days {

}

.action-stats__donate {
	.button-rounded();
	margin: 0 auto;
	margin-top: 25px;
}

.no-touchevents {
	.action-stats__donate {
		&:hover, &:focus, &:active {
			background: #009ad5;
			color: #fff;
			outline: 0;

			.icon--default {
				display: none;
			}

			.icon--hover {
				display: inline;
			}
		}
	}
}

.action-stats__prog-bar.prog-bar {
	border-radius: 0;

	.prog-bar__bar {
		border-radius: 0;
	}
}