.featured-popup {
	text-align: center;
	padding: 0 !important;
	&:before {
		content: "";
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -4px;
	}

	.modal-content {
		text-align: left;
		border: none;
		display: table;
		width: 100%;
		border-radius: 24px;
		box-shadow: 0 24px 48px 0 rgba(252, 118, 19, 0.04), 0 -20px 24px 0 rgba(252, 118, 19, 0.02);
		@media (min-width: @screen-sm-min) {
			padding: 20px;
		}
	}

	.modal-dialog {
		display: inline-block;
		text-align: left;
		vertical-align: middle;
		margin: 20px;
		@media (min-width: @screen-sm-min) {
			width: 600px;
			margin: 30px auto;
		}

		@media (min-width: @screen-md-min) {
			width: 864px;
		}
	}
}

.featured-popup__close {
	width: 15px;
	height: 15px;
	border: 0;
	outline: 0;
	position: absolute;
	top: 20px;
	right: 20px;
	padding: 0;
	cursor: pointer;
	background: transparent;
	-webkit-appearance: none;
	&:before {
		content: "";
		position: absolute;
		background-image: url("../../images/icon_close.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		width: 15px;
		height: 15px;
		top: 0;
		left: 0;
	}
	@media (min-width: @screen-sm-min) {
		width: 20px;
		height: 20px;
		top: 25px;
		right: 25px;
		&:before {
			width: 20px;
			height: 20px;
		}
	}
}

.featured-popup__title {
	.avenir-black;
	font-size: 26px;
	line-height: 1;
	line-height: 30px;
	margin-top: 0;
	margin-bottom: 15px;
	@media (min-width: @screen-sm-min) {
		margin-bottom: 25px;
		font-size: 30px;
		line-height: 41px;
	}
}

.featured-popup__image {
	display: none;
	background-size: cover;
	border-radius: 16px;
	@media (min-width: @screen-sm-min) {
		display: table-cell;
		width: 240px;
	}

	@media (min-width: @screen-md-min) {
		width: 300px;
	}
}

.cta__text {
	font-size: 16px;
    line-height: 26px;
}

.featured-popup__text {
	display: table-cell;
	padding: 50px 20px 15px 20px;

	@media (min-width: @screen-sm-min) {
		padding: 35px;
	}
}

.featured-popup__buttons {
	@media (min-width: @screen-sm-min) {
		display: flex;
	}

	a {
		font-size: 16px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 25px;
	}
	a:last-of-type {
		margin-top: 10px;
		box-shadow: none;
		&:focus,
		&:hover {
			box-shadow: none;
		}
		@media (min-width: @screen-sm-min) {
			margin-top: 25px;
		}
	}
}
