@progbar-border-radius: 8px;

.prog-bar {
	height: 17px;
	border-radius: @progbar-border-radius;
	transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
}

.prog-bar__bar {
	position: relative;
	height: 100%;
	width: 0%;
	transition: width 1s;
	border-radius: @progbar-border-radius;
}

.prog-bar__amount {
	.avenir-black();
	font-size: 11px;
	letter-spacing: 0.9px;
	border-radius: @progbar-border-radius;
	position: absolute;
	right: 0;
	bottom: -24px;
	line-height: 1;
	padding: 5px 5px 2px;
	transform: translateX(50%);
	-webkit-transform: translateX(50%);
	opacity: 0;
	transition: opacity .3s;

	.animation-finished & {
		opacity: 1;
	}
}

.prog-bar--action {
	background: #fff;
	//border-radius: 0;

	.prog-bar__bar {
		background: @web-orange;
		//border-radius: 0;
	}

	.prog-bar__amount {
		background: @web-orange;
		color: #fff;
	}
}

.prog-bar--shift {
	.prog-bar__amount {
		right: 30px;
	}
}