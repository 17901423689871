.reporter {
	.clearfix();
	padding: 20px 0;
	border-bottom: 1px solid #fff;
	color: #fff;

	a {
		color: #fff;
	}

	&:first-child {
		border-top: 1px solid #fff;
	}

	@media(min-width: @screen-md-min) {
		padding: 40px 0;
	}
}

.reporter__image {
	.square(52px);
	float: left;
	margin-right: 16px;

	@media(min-width: @screen-sm-min) {
		.square(92px);
		margin-right: 32px;
	}
}

.reporter__text {
	overflow: hidden;
}

.reporter__title {
	font-size: 17px;
	line-height: 1.12;
	margin-bottom: 4px;
}

.reporter__age {
	font-size: 12px;
	line-height: 1.58;
}

.reporter__content {
	font-size: 16px;
	line-height: 1.5;
	margin-top: 10px;

	> *:last-child {
		margin-bottom: 0;
	}
}