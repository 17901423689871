.entry {
	color: #fff;
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 40px;

	ul, ol {
		padding-left: 17px;
	}

	img {
		max-width: 100%;
		height: auto;
	}
}