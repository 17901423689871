.project-content {
	background: #FF5913;
}

html.cssmask .project-content {
	@media(min-width: @screen-md-min) {
		padding-bottom: 160px;
	}
}

// Project info
// -------------------------

.project-info {
	background: @text-color; // temp
	color: #fff; // temp
	position: relative;
	overflow: hidden;
	

	@media(min-width: 600px) {
		display: flex;
		display: -webkit-flex;
	}
}

.project-info__image {
	background-size: cover;
	background-position: center center;
	height: 180px;

	@media(min-width: 600px) {
		width: 50%;
		height: auto
	}

	@media(min-width: @screen-md-min) {
		width: 62.5%;
	}
}

.project-info__content {
	background: @text-color;
	background-image: url(../../images/krijtbord_groot.jpg);
	background-size: cover;
	color: #fff;
	padding: 40px;
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;
	overflow: auto;

	.project-actions__cta{
		margin-top: 20px;
	}

	@media(min-width: 600px) {
		width: 50%;
		padding: 40px 40px;
	}

	@media(min-width: @screen-md-min) {
		width: 37.5%;
		padding: 60px 80px;
	}
}

.project-info__text {
	width: 100%;

	@media(min-width: 600px) {
		max-width: 330px;
	}
}

.project-info__theme {
	.tag();
	background: #FF5913;
	color: #fff;
	border-color: #FF5913;
	margin-bottom: 20px;
}

.project-info__title {
	.after-line();
	letter-spacing: -1.2px;
	padding-bottom: 15px;
}

.project-info__cta {
	margin-top: 20px;
}

// Project actions
// -------------------------

.project-actions {
	padding: 40px 0;

	.lead-text {
		p {
			margin-bottom: 30px;
		}
	}

	@media(min-width: @screen-sm-min) {
		padding-top: 80px;
		padding-bottom: 120px;
		background-image: url(../../pngs/maskmetschaduw4.png);
		background-position: left bottom -450px;
		background-repeat: repeat-x;
	}

	// @media(min-width: @screen-md-min) {
	// 	padding: 80px 0;
	// }
}

.project-actions__text {
	.make-md-column(3.9);
	margin-bottom: 30px;

	@media(min-width: @screen-md-min) {
		margin-bottom: 0;
	}
}

.project-actions__title, .home-projects__title {
	.title--biggest();
	padding-bottom: 12px;
	position: relative;
	color:#fff;
	margin-bottom: 35px;

	.after-line();
}

.project-actions__cta {
	&:hover, &:focus, &:active {
		//color: #ff6411;
	}
}

.project-actions__actions {
	.make-md-column(8.1);

	> .row {
		margin-left: -10px;
		margin-right: -10px;
        float: right;
	}

	.card {
		.make-sm-column(6);
		padding-left: 10px;
		padding-right: 10px;

		@media(min-width: @screen-md-min) {
			max-width: 344px;
		}
	}
}

// Project activities
// -------------------------

.project-activities {
	padding: 0;

	@media(min-width: @screen-md-min) {
		padding: 80px 0;
	}
}

.project-activities__activities {
	.event {
		width: 100%;
	}

	@media(min-width: @screen-md-min) {
		flex-flow: row nowrap;
		justify-content: center;
		-webkit-justify-content: center;
		display: flex;
		display: -webkit-flex;
		flex-flow: row wrap;
		-webkit-flex-flow: row wrap;

		.event {
			width: 33%;
		}
	}
}

// Project actions archive
// -------------------------

.project__mask {
	height: 80px;
	position: absolute;
	top: -80px;
	width: 100%;
	display: none;

	@media(min-width: @screen-sm-min) {
		display: block;
	}
}

.project-actions-archive {
	padding: 40px 0;
	position: relative;

	@media(min-width: @screen-sm-min) {
		// position: relative;

		// &:before {
		// 	content: '';
		// 	width: 100%;
		// 	height: 279px;
		// 	background-image: url(../../images/pngs/oranje.png);
		// 	background-repeat: repeat-x;
		// 	background-position: center top;
		// 	background-size: 1280px 279px;
		// 	position: absolute;
		// 	top: -85px;
		// 	left: 0;
		// 	pointer-events: none;
		// }
	}

	@media(min-width: @screen-md-min) {
		padding: 80px 0;
	}

	.row {
		margin-left: -15px;
		margin-right: -15px;
	}

	.card {
		.make-sm-column(6);
		.make-md-column(4);
		padding-left: 15px;
		padding-right: 15px;
	}
}