.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: #fff; // Default fill color
}

// Hover icon hidden by default
.icon--hover {
  display: none;
}

.icon-activities_hover {
  width: 0.75em;
}

.icon-activities {
  width: 0.75em;
}

.icon-chevron {
  width: 1.8330078125em;
}

.icon-comment {
  width: 1.0908203125em;
}

.icon-information_hover {
  width: 1.0712890625em;
}

.icon-information {
  width: 1.0712890625em;
}

.icon-logout_hover {
  width: 1.3076171875em;
}

.icon-logout {
  width: 1.3076171875em;
}

.icon-report_hover {
  width: 1.1669921875em;
}

.icon-reporters_hover {
  width: 0.619140625em;
}

.icon-reporters {
  width: 0.619140625em;
}

.icon-search {
  width: 0.9501953125em;
}

.icon-share-bottom {
  width: 0.875em;
}

.icon-share-right {
  width: 1.154296875em;
}

.icon-supporters_hover {
  width: 1.0771484375em;
}

.icon-supporters-small {
  width: 1.099609375em;
}

.icon-supporters {
  width: 1.0771484375em;
}

.icon-tag-right_hover {
  width: 2.3916015625em;
}

.icon-tag-right {
  width: 2.3916015625em;
}

.icon-user_hover {
  width: 0.875em;
}

.icon-user {
  width: 0.875em;
}

.icon-volume {
  width: 1.4404296875em;
}
