.created-content {
	background: #FF5913;
	padding: 40px 0;

	@media(min-width: @screen-md-min) {
		padding: 80px 0;
	}
}

html.cssmask .created-content {
	@media(min-width: @screen-md-min) {
		padding-bottom: 160px;
	}
}