.header {
	.avenir-black();
	height: @edu-header-height-m;
	background-color: #fff;
	color: @text-color;
	font-size: 16px;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 1000;

	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	justify-content: center;
	text-align: center;

	transition: height .3s;
	transition-delay: .2s;

	.mobile-menu-active & {
		height: 100%;
		transition-delay: 0s;
	}

	@media(min-width: @screen-md-min) {
		font-size: 13px;
		position: relative;
		top: auto;
		height: @edu-header-height;

		display: block;
		text-align: left;

		.mobile-menu-active & {
			height: @edu-header-height;
		}
	}
}

.header__content {
	.clearfix();
	position: relative;
	height: 100%;
}

.header__logo {
	display: inline-block;
	position: absolute;
	left: 24px;
	top: 18px;

	h1 {
		display: inline;

		.header__logo-payoff {
			margin-left: 0;
		}
	}

	@media(min-width: @screen-md-min) {
		left: 50%;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		//margin-left: -49px;
		margin-left: -60px;
	}
}

.header__logo-img {
	display: none;

	@media(min-width: @screen-md-min) {
		display: block;
	}
}

.header__logo-img-mobile {
	display: block;

	@media(min-width: @screen-md-min) {
		display: none;
	}
}

.header__logo-main {
	//width: 98px;
	width: 120px;
	height: 62.44px;
}

.header__logo-payoff {
	//width: 117px;
	width: 130px;
	height: 38.88px;
	margin-left: 8px;
}

.hamburger {
	position: absolute;
	right: 9px;
	top: 0px;
	padding-top: 24px;

	&:focus, &:active {
		outline: 0;
	}

	@media(min-width: @screen-md-min) {
		display: none;
	}
}

.header-menu {
	display: inline-block;
	opacity: 0;
	visibility: hidden;
	transition: opacity .15s, visibility .15s;

	.mobile-menu-active & {
		opacity: 1;
		visibility: visible;
		transition-delay: .35s;
	}

	@media(min-width: @screen-md-min) {
		height: 100%;
		display: table;
		opacity: 1;
		visibility: visible;
	}

	ul {
		.reset-list();
		
		@media(min-width: @screen-md-min) {
			display: table-cell;
			vertical-align: middle;
		}
	}

	li {
		display: block;
		margin: 40px 0;
		position: relative;


		+ li {
			&:after {
				width: 40px;
				height: 1px;
				background: @text-color;
				position: absolute;
				top: -20px;
				left: 50%;
				margin-left: -20px;
				content: '';	
			}
		}

		@media(min-width: @screen-md-min) {
			display: inline-block;
			margin: 0 10px;
			position: static;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			+ li {
				&:after {
					display: none;	
				}
			}
		}

		&.active {
			a:after {
				width: 100%;
			}
		}
	}

	a {
		color: @text-color;
		display: inline-block;
		padding: 8px 0 6px 0;
		line-height: 1;
		background-color: transparent;
		position: relative;

		&:after {
			content: '';
			width: 0;
			height: 2px;
			background-color: @text-color;
			position: absolute;
			left: 0;
			bottom: 0;
			transition: width .3s;
		}

		// &:focus, &:active {
		// 	&:after {
		// 		width: 100%;
		// 	}
		// }
	}
}

.no-touchevents {
	.header-menu {
		li a {
			&:hover, &:focus, &:active {
				&:after {
					width: 100%;
				}
			}
		}
	}
}

.header-menu__account {
	img {
		.square(27px);
		border-radius: 50%;
		display: inline;
		margin-right: 6px;
	}

	&.active a {
		span:after {
			width: 100%;
		}
	}

	a {
		padding: 0;

		&:after {
			display: none;
		}

		// &:hover {
		// 	span:after {
		// 		width: 100%;
		// 	}
		// }
	}

	span {
		padding: 8px 0 6px 0;
		display: inline-block;
		line-height: 1;
		background-color: transparent;
		position: relative;

		&:after {
			content: '';
			width: 0;
			height: 2px;
			background-color: @text-color;
			position: absolute;
			left: 0;
			bottom: 0;
			transition: width .3s;
		}
	}
}

.no-touchevents {
	.header-menu__account {
		a {
			&:hover, &:focus, &:active {
				span:after {
					width: 100%;
				}
			}
		}
	}
}

.header-menu--primary {
	@media(min-width: @screen-md-min) {
		float: left;
	}

	a {
		transition: color .3s, background .15s;
	}
}

.header-menu--secondary {
	margin-top: -20px;

	li:last-child a {
		background-color: @orange;
		color: #fff;
		border: 2px solid @orange;
		border-radius: 32px;
		padding: 8px 12px 6px 12px;

		&:hover {
			background-color: #fff;
			color: @orange;
		}

		&:after {
			display: none;
		}
	}

	@media(min-width: @screen-md-min) {
		float: right;
		margin-top: 0;
	}
}