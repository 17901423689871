.donation-thanks-content {
	background-color: #ffa701;
	padding: 40px 0;

	@media(min-width: @screen-md-min) {
		padding: 80px 0;
	}
}

html.cssmask .donation-thanks-content {
	@media(min-width: @screen-md-min) {
		padding-bottom: 160px;
	}
}

.donation__comment-form {
	//margin-top: -25px;
}