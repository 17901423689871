.side-menu {
	ul {
		.reset-list();
		.avenir-black();
		margin-bottom: 30px;
		color: #fff;
		font-size: 12px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
	}

	li {
		display: block;

		&.active a {
			&:after {
				height: 3px;
				bottom: 0;
			}

			.icon--default {
				display: none;
			}

			.icon--hover {
				display: block;
			}
		}
	}

	a {
		display: block;
		padding: 15px 15px 15px 30px;
		color: #fff;
		position: relative;

		&:after {
			content: '';
			width: 100%;
			height: 1px;
			background-color: #fff;
			position: absolute;
			left: 0;
			bottom: 1px;
		}

		// &:hover {
		// 	.icon--default {
		// 		display: none;
		// 	}

		// 	.icon--hover {
		// 		display: block;
		// 	}

		// 	&:after {
		// 		height: 2px;
		// 	}
		// }
	}

	.icon {
		position: absolute;
		left: 0;
	}

	.icon-home, .icon-home_hover {
		width: 14px;
		height: 16px;
	}

	.icon-updates, .icon-updates_hover {
		width: 14px;
		height: 14px;
	}

	.icon-activities, .icon-activities_hover {
		width: 15px;
		height: 19px;
	}

	.icon-information, .icon-information_hover {
		width: 15px;
		height: 13.3px;
	}

	.icon-supporters, .icon-supporters_hover {
		width: 14px;
		height: 12.2px;
	}

	.icon-sponsors, .icon-sponsors_hover {
		width: 16px;
		height: 15.2px;
	}

	.icon-reporters, .icon-reporters_hover {
		width: 13px;
		height: 19px;
	}

	.icon-user, .icon-user_hover {
		width: 14px;
		height: 15.8px;
	}

	.icon-logout, .icon-logout_hover {
		width: 14px;
		height: 12.6px;
	}
}

.no-touchevents .side-menu {
	a {
		&:hover {
			.icon--default {
				display: none;
			}

			.icon--hover {
				display: block;
			}

			&:after {
				height: 2px;
			}
		}
	}
}

.side-menu--page {
	ul {
		padding-top: 30px;
	}

	a {
		padding-left: 0;
		padding-right: 0;
	}

	@media(min-width: @screen-md-min) {
		ul {
			padding-top: 0;
		}
	}
}