// General donation styles
// -------------------------

.donation-content {
	background: #f6f6f6;
	padding: 40px 0;
	position: relative;

	@media(min-width: @screen-md-min) {
		padding: 80px 0;
	}
}

body.donation {
	background: #f6f6f6;
}

// Create action header
// -------------------------

.donation-header {
	.avenir-heavy();
	color: #fff;
	font-size: 14px;
	line-height: 1.71;
	background: @web-orange;
	padding-top: 20px;
	padding-bottom: 20px;

	a {
		color: #fff;
	}
}


// Donation form
// -------------------------

.donation-action-owner {
	position: absolute;
	margin-left: -42px;
	left: 50%;
	top:-42px;
	border-radius: 50%;
	display: none;

	@media(min-width: @screen-md-min) {
		display: block;
	}
}

.donation-section {
	max-width: 500px;
	width: 100%;
	margin: 0 auto;
	background: #fff;
	padding: 50px 15px;
	text-align: center;
	border-radius: 8px;
}

.donation-section-footer {
	max-width: 500px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
	text-align: center;
	margin-top: 30px;
}

.donation-section-footer__logos {
	img {
		margin: 0 15px 20px 15px;
	}
}

.donation-section__title {
	padding-bottom: 12px;
	position: relative;
	margin-bottom: 30px;

	.after-line();

	&:after {
		background-color: @text-color;
		left: 50%;
		margin-left: -25px;

		@media(min-width: @screen-md-min) {
			margin-left: -35px;
		}
	}
}

.donation-picker__form {
	text-align: center;

	label {
		.avenir-black();
		font-weight: 400;
		margin-bottom: 10px;
		margin-left: 8px;
		margin-right: 8px;
		display: block;
		vertical-align: middle;
		font-size: 29px;
		line-height: 1;
		cursor: pointer;
		position: relative;


		@media(min-width: 500px) {
			display: inline-block;
			margin-bottom: 0;
		}
	}
}

.donation-picker__amount {
	span {
		border-radius: 4px;
		border: 2px solid @text-color;
		padding: 8px 20px 4px 20px;
		display: inline-block;
		line-height: 1;
		transition: background .15s, border .15s, color .3s;
		background: #fff;
	}

	> input {
		position: absolute;
		top: 5px;
		left: 5px;
		z-index: -1;

		&:checked {
			+ span {
				background: @blue-light;
				border-color: @blue-light;
				color: #fff;
			}
		}
	}
}

.donation-picker__euro {
	font-size: 24px;
	display: inline-block;
}

.donation-picker__euro--custom {
	position: absolute;
	left: 15px;
	top: 14px;
	pointer-events: none;
}

.donation-picker__wrapper {
	position: relative;
	width: 150px;
	margin: 0 auto;
}

.donation-picker__custom-amount {
	position: relative;

	input[type="number"] {
		border-radius: 4px;
		border: 2px solid @text-color;
		padding: 8px 0px 4px 40px;
		display: inline-block;
		line-height: 1;
		transition: background .15s, border .15s, color .3s;
		width: 150px;
		height: 45px;

        -moz-appearance: textfield;

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

		&:focus {
			outline: 0;
		}
	}

	.donation-picker__wrapper > input[type="radio"] {
		display: none;
		position: absolute;
		top: 5px;
		left: 5px;
		z-index: -1;

		&:checked {
			+ input[type="number"] {

			}
		}
	}
}

.donation-picker__custom-amount {

}

.donation-picker__submit {
	margin-top: 40px;

	&:disabled {
		background: #ccc;
		cursor: not-allowed;

		&:hover {
			background: #ccc;
		}
	}
}

.donation-payment {
	text-align: center;
}

.donation-payment-option {
	.avenir-black();
	font-weight: 400;
	margin-bottom: 10px;
	margin-left: 8px;
	margin-right: 8px;
	display: block;
	vertical-align: middle;
	line-height: 1;
	cursor: pointer;
	position: relative;

	span {
		border-radius: 4px;
		border: 2px solid @text-color;
		padding: 22px 20px 18px 20px;
		display: inline-block;
		line-height: 1;
		transition: background .15s, border .15s, color .3s;
		background: #fff;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 1px;
	}

	> input {
		position: absolute;
		top: 5px;
		left: 5px;
		z-index: -1;

		&:checked {
			+ span {
				background: @blue-light;
				border-color: @blue-light;
				color: #fff;

				.donation-payment-method__img {
					path {
						fill: #fff;
					}
				}
			}
		}
	}

	@media(min-width: 500px) {
		display: inline-block;
		margin-bottom: 0;
	}
}

.donation-payment-option--ideal {
	span {
		position: relative;
		padding-left: 64px;
	}

	.donation-payment-method__img {
		position: absolute;
		left: 20px;
		top: 13px;
	}
}

.donation-details {
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;

	@media(min-width: @screen-md-min) {
		padding-left: 80px;
		padding-right: 80px;
	}
}

.donation-section--error {
	.button-rounded {
		margin-top: 30px;
	}
}

.donation-section__error-text {
	font-size: 16px;
	line-height: 1.5;

	@media(min-width: @screen-md-min) {
		padding: 0 40px;
	}
}

// Inline comment form

.donation-comment-section {
	margin-top: 10px;
}

.donation-comment-section__form {
	position: relative;

	.form-control, .form-group {
		margin-bottom: 0;
	}
}

.donation-comment-section__activate {
	transition: opacity .15s;

	.active & {
		opacity: 0;
		cursor: default;
	}
}

.donation-comment-section__deactivate {
	transition: opacity .15s;
	position: absolute;
	right: 0;
	bottom: 2px;
	opacity: 0;

	.active & {
		transition-delay: .3s;
		opacity: 1;
	}
}

// .ca-form-section {
// 	max-width: 500px;
// 	width: 100%;
// 	margin: 0 auto;
// 	background: #fff;
// 	padding: 50px 70px;
// 	text-align: center;
// }

// .ca-form-section__title {
// 	padding-bottom: 12px;
// 	position: relative;
// 	margin-bottom: 30px;

// 	&:after {
// 		content: '';
// 		width: 70px;
// 		height: 7px;
// 		background-color: @text-color;
// 		position: absolute;
// 		bottom: 0;
// 		left: 50%;
// 		margin-left: -35px;
// 	}
// }

// .ca-form {
// 	.textarea-label {
// 		background: #fff;
// 	}
// }

// .ca-form__submit {
// 	margin-top: 20px;
// }