.event {
	.clearfix();
	display: table;
	width: 100%;
	margin-bottom: 20px;

	&:hover, &:focus, &:active {
		.event__title:after {
			width: 100px;
		}
	}
}

.event__date {
	.avenir-black();
	width: 85px;
	text-align: center;
	background-color: #fff;
	color: @text-color;
	border-radius: 8px;
	text-transform: uppercase;
	padding: 18px 0;
	display: table-cell;
	vertical-align: middle;
}

.event__day {
	font-size: 34px;
	line-height: 1;
	letter-spacing: -0.9px;
	display: block;
}

.event__month {
	font-size: 13px;
	line-height: 1;
	letter-spacing: -0.3px;
	display: block;

	&:after {
		content: '';
		background-color: @text-color;
		width: 16px;
		height: 3px;
		margin: 5px auto 0 auto;
		display: block;
	}
}

.event__title-wrapper {
	display: table-cell;
	vertical-align: middle;
	padding-left: 30px;

	@media(min-width: @screen-lg-min) {
		padding-right: 50px;
		padding-left: 30px;
	}
}

.event__title {
	padding-bottom: 10px;
	overflow: hidden;
	color: #fff;
	font-size: 19px;
	line-height: 1.37;
	position: relative;

	&:after {
		content: '';
		width: 50px;
		height: 4px;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
		transition: width .15s;
	}
}

.event__action {
	.tag();
	color: #fff;
	margin-top: 5px;
}

.event--big {
	.event__date {
		width: 100px;
		height: 110px;
	}

	.event__month {
		font-size: 15px;
	}

	.event__day {
		font-size: 40px;
	}

	.event__title {
		font-size: 21px;
		line-height: 1.24;
	}
}