.media-grid {
	.reset-list();
	.row();
	margin-left: -10px;
	margin-right: -10px;

	li {
		float: left;
		position: relative;
		min-height: 1px;
		width: 50%;
		height: 96px;
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 8px;
		overflow: hidden;
		margin-bottom: 20px;

		@media(min-width: 480px) {
			width: 25%;
		}
	}

	a {
		display: block;
		width: 100%;
		height: 100%;
		border-radius: 8px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}

	img {
		.img-responsive();
		border-radius: 8px;
	}
}