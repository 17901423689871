.ua-success {
	padding: 80px 0;

	.lead-text {
		p {
			margin-bottom: 30px;
		}
	}
}

.ua-success__text {
	.make-md-column(4);
	.make-md-column-offset(1);
	.make-md-column-push(6);
	margin-bottom: 30px;
}

.ua-success__title, .home-projects__title {
	.title--biggest();
	padding-bottom: 12px;
	position: relative;
	color:#fff;
	margin-bottom: 35px;

	&:after {
		content: '';
		width: 70px;
		height: 8px;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

.ua-success__cta {

}

.ua-success__share-buttons {
	margin-top: 40px;
	margin-bottom: 40px;

	.share-icon, .icon {
		.square(34px);
	}

	.share-icon--email, .icon-share-right {
		width: 39px;
	}
}

.ua-success__actions {
	.make-md-column(4);
	.make-md-column-offset(2);
	.make-md-column-pull(5);

	> .row {
		margin-left: -10px;
		margin-right: -10px;
	}

	.card {
		.make-md-column(12);
		padding-left: 10px;
		padding-right: 10px;
	}
}

.ua-succes--unsub {
	.ua-success__text {
		//.make-md-column(8);
		//.make-md-column-offset(2);
		max-width: 450px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		float: none;
		text-align: center;
		left: auto;
	}

	.ua-success__title {
		.title--biggest();
		padding-bottom: 12px;
		position: relative;
		color:#fff;
		margin-bottom: 35px;

		&:after {
			content: '';
			width: 70px;
			height: 8px;
			background-color: #fff;
			position: absolute;
			bottom: 0;
			left: 50%;
			margin-left: -35px;
		}
	}
}