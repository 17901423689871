.action__top--schm{
    text-align: center;
}
.schm-action__title{
    .avenir-black();
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
	line-height: 1.1;

	@media(min-width: @screen-md-min) {
        margin-bottom: 30px;
		font-size: 40px;
	}

	@media(min-width: @screen-lg-min) {
        margin-bottom: 40px;
		font-size: 50px;
	}
}

.schm-action__text{
    margin-bottom: 20px;
    p{
        font-size: 16px;
        line-height: 1.48;
        text-align: center;
        color: #fff;
    }

	@media(min-width: @screen-md-min) {
        margin-bottom: 30px;
		p{
            font-size: 19px;
        }
	}

	@media(min-width: @screen-lg-min) {
        margin-bottom: 40px;
		p{
            font-size: 21px;
        }
	}
}

.schm-action__instruction{
    text-align: center;
    margin: 0 auto 40px auto;
    p{
        font-size: 13px;
        line-height: 1.23;
    }
    .button-follow-action{
        text-decoration: none;
        margin-bottom: 25px;
        font-size: 13px;
    }
    @media(min-width: @screen-sm-min) {
        width: 60%;
        .button-follow-action{
            font-size: 14px;
        }
    }
}

.schm-action__latest-updates{
    margin-top: 40px;
    @media(min-width: @screen-md-min) {
        margin-top: 100px;
    }
}

.schm-action__explanation{
    margin-top: 60px;
    text-align: center;
    @media(min-width: @screen-md-min) {
        margin-top: 100px;
    }
}

.schm-action__password{
    margin: 0 auto 60px auto;
    width: 60%;
    text-align: center;
}

.form-group--vlog{
    label{
        display: block;
    }
}

.videowrapper{
    width: 100%;
}

.button-vlog{
    .avenir-black();
    display: block;
    width: 100%;
	text-align: center;
    font-size: 15px;
    padding: 16px 35px;
    color: #000;
	text-transform: uppercase;
	letter-spacing: 1px;
    border-radius: 2px;
    border: solid 1px #e8e8e8;
    background-color: #ffffff;
}

.button-cancel {
    margin-bottom: 30px;
}

.schoolmates-hide{
    display: none;
}