// General action styles
// -------------------------

@sa-section-margin-b: 60px;

.action-content {
	background-color: @yellow-sea;
}

// Left column
// -------------------------

.action__follow-action {
	width: 100%;
}

.action__follow-action--mobile {
	margin-top: 30px;
	width: auto;

	@media(min-width: @screen-md-min) {
		display: none;
	}
}

.action__unfollow-action--mobile {
	@media(min-width: @screen-md-min) {
		display: none;
	}
}

.action-manage {
	background: @orange-peel;
	padding: 15px 0;

	@media(min-width: @screen-sm-min) {
		padding: 30px 0;
	}

	@media(min-width: @screen-md-min) {
		margin-top: -@edu-p-v;
		margin-bottom: 10px;
	}
}

// Action manager
.action-manage__wrapper {
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;
	position: relative;
	height: 48px; // ?

	@media(min-width: @screen-sm-min) {
		width: 192px;
		margin-left: @edu-p / 2;
		padding: 0;
	}

	@media(min-width: @screen-md-min) {
		width: 192px;
		margin: 0 auto;
	}
}

.action-manage__toggle {
	.button-follow-action();
	font-size: 12px;
	letter-spacing: 0.5px;
	padding: 17px 25px 14px 25px;
	width: 100%;
	transition: width .2s, background .15s;
	overflow: hidden;
	height: 48px;
	position: relative;

	span {
		visibility: visible;
		opacity: 1;
		transition: opacity .15s, visibility .15s;
		transition-delay: .2s;
	}

	.icon-chevron {
		width: 10.2px;
		height: 5.8px;
		position: relative;
		top: -2px;
		margin-left: 10px;
		visibility: visible;
		opacity: 1;
		transition: opacity .15s, visibility .15s;
		transition-delay: .4s;
	}

	.icon-cancel {
		fill: @text-color;
		visibility: hidden;
		opacity: 0;
		width: 10px;
		height: 10px;
		position: absolute;
		top: 19px;
		left: 20px;
		transition: opacity .15s, visibility .15s;
		margin-left: 0;
	}

	.active & {
		width: 48px;
		height: 48px;
		background: #fff !important;

		span, .icon-chevron {
			visibility: hidden;
			opacity: 0;
			transition: all 0s ease 0s;
			transition-delay: 0s;
		}

		.icon-cancel {
			visibility: visible;
			opacity: 1;
			transition-delay: .25s;
		}
	}
}

.no-touchevents {
	.action-manage__toggle {
		&:hover, &:focus, &:active {
			background: transparent;
			color: #fff;
			outline: 0;

			.icon--default {
				display: none;
			}

			.icon--hover {
				display: inline;
			}
		}
	}
}

.action-manage__actions {
	width: 278px;
	background: #fff;
	border-radius: 6px;
	position: absolute;
	top: 54px;
	left: @edu-p / 2;
	visibility: hidden;
	opacity: 0;
	transform: translateX(20px);
	-webkit-transform: translateX(20px);
	transition: opacity .15s, visibility .15s, transform .4s, -webkit-transform .4s;
	z-index: 500;
	padding: 15px 20px;

	.active & {
		opacity: 1;
		visibility: visible;
		transform: translateX(0);
		-webkit-transform: translateX(0);
		transition: opacity .6s, visibility .15s, transform .25s, -webkit-transform .25s;
		transition-delay: .05s;
		-webkit-transition-delay: .05s;
	}

	@media(min-width: @screen-sm-min) {
		padding: 15px 30px;
		top: 0px;
		left: 64px;
		padding: 15px 30px;
	}
}

.action-manage__menu {
	.reset-list();
	.avenir-black();
	color: @text-color;
	font-size: 12px;
	letter-spacing: 0.5px;
	text-transform: uppercase;

	.icon {
		fill: @text-color;
		position: absolute;
		left: 0;
	}

	.icon-plus {
		width: 11px;
		height: 11px;
		top: 16px;
	}

	.icon-edit-small {
		width: 12px;
		height: 12px;
	}

	.icon-edit_hover {
		width: 22px;
		height: 22px;
		left: -5px;
		top: 10px;
	}

	.icon-updates, .icon-updates_hover {
		width: 14px;
		height: 14px;
	}

	.icon-activities, .icon-activities_hover {
		width: 15px;
		height: 19px;
	}

	.icon-information, .icon-information_hover {
		width: 15px;
		height: 13.3px;
	}

	.icon-supporters, .icon-supporters_hover {
		width: 14px;
		height: 12.2px;
	}

	.icon-sponsors, .icon-sponsors_hover {
		width: 16px;
		height: 15.2px;
	}

	.icon-reporters, .icon-reporters_hover {
		width: 12px;
		height: 19px;
	}

	li {
		display: block;
		border-top: 1px solid @text-color;

		&:first-child {
			border-top: none;
		}
	}

	a {
		display: block;
		padding: 15px 0px 12px 25px;
		color: @text-color;
		position: relative;

		// &:hover {
		// 	.icon--default {
		// 		display: none;
		// 	}

		// 	.icon--hover {
		// 		display: block;
		// 	}
		// }
	}
}

.no-touchevents {
	.action-manage__menu {
		a {
			&:hover {
				.icon--default {
					display: none;
				}

				.icon--hover {
					display: block;
				}
			}
		}
	}
}

.action-mm {
	margin-top: 10px;

	@media(min-width: @screen-md-min) {
		display: block;
		margin-top: 0;
	}
}

.action-mm-toggle {
	.avenir-black();
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 0.5px;
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;
	padding-top: 16px;
	padding-bottom: 14px;
	position: relative;
	//background: @orange-peel;
	display: block;
	color: #fff;

	.icon-chevron {
		width: 10.2px;
		height: 5.8px;
		position: absolute;
		right: 30px;
		top: 21px;
	}

	&:hover, &:focus, &:active {
		color: #fff;
		outline: 0;
	}

	&.is-active {
		background: transparent;

		&:after {
			position: absolute;
			bottom: 0;
			left: @edu-p / 2;
			right: @edu-p / 2;
			content: '';
			height: 1px;
			background: #fff;
		}
	}

	@media(min-width: @screen-md-min) {
		display: none;
	}
}

// Center column
// -------------------------

// Topbar containing project, theme and sharing buttons
.action__top {
	margin-bottom: 24px;
	display: table;
	width: 100%;
	table-layout: fixed;

	@media(min-width: @screen-md-min) {
		margin-bottom: 24px;
	}
}

// Share buttons
.action__sharing {
	display: table-cell;
	vertical-align: middle;
	width: 102px;
}

// Breadcrumbs
.action-breadcrumbs {
	.reset-list();
	display: table-cell;
	vertical-align: middle;

	li {
		margin-bottom: 10px;
		float: left;
		clear: both;

		@media(max-width: 320px) {
			width: 80%;

			span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}

		}
	}

	@media(min-width: @screen-lg-min) {
		li {
			margin-bottom: 0;
			float: none;
		}

		li + li {
			margin-left: 10px;
		}
	}
}

// Summary
.action__summary {
	color: #fff;
	margin-bottom: @sa-section-margin-b / 2;
	a{
		color: inherit;
		text-decoration: underline;
	}
	@media(min-width: @screen-md-min) {
		margin-bottom: @sa-section-margin-b;
	}
}

.action__summary-title {
	.title--biggest();
	.after-line();
}

.action__summary-text {
	p {
		margin-bottom: 0;
	}
}

// Latest posts
.action__latest-updates {
	margin-bottom: @sa-section-margin-b / 2;

	.more-link {
		margin-top: 10px;
	}

	@media(min-width: @screen-md-min) {
		margin-bottom: @sa-section-margin-b;
	}
}

// Latest activities
.action__latest-activities {
	.clearfix();
	margin-bottom: @sa-section-margin-b / 2;

	.event {
		@media(min-width: @screen-md-min) {

		}

		@media(min-width: @screen-lg-min) {
			float: left;
			width: 50%;
		}
	}

	.more-link {
		float: left;
		clear: both;
		margin-top: 10px;
	}

	@media(min-width: @screen-md-min) {
		margin-bottom: @sa-section-margin-b;
	}
}

// Reporters
.action__reporters {
	margin-top: 30px;

	@media(min-width: @screen-md-min) {
		margin-top: 40px;
	}
}

// Sponsors

.action__sponsors {
	margin-top: 30px;

	@media(min-width: @screen-md-min) {
		margin-top: 40px;
	}
}

// Reporters

.action__supporters {
	margin-top: 30px;

	@media(min-width: @screen-md-min) {
		margin-top: 40px;
	}
}

.action--whitelink{
	.button-rounded();
	margin: 15px 0;
	color: white;
	&:hover,
	&:focus{
		color: white;
	}
}