// General home styles
// -------------------------

.home {

}

.home-content {
	background: @blue;
}

// Home video
// -------------------------

.home-video {
	position: relative;
	background: #F5F5F5; // temp

	> .container {
		height: 100%;
		position: relative;

	}

	.icon-activities_hover {
		fill: #000;
	}
}

.home-video__container {
	.container();
	position: relative;
	height: 100%;

	@media(min-width: @screen-sm-min) {
		width: 100%;
		padding: 0;
		max-width: 1170px;
	}
}

.home-video__cta {
	position: absolute;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	z-index: 20;

	@media(max-width: @screen-sm-min) {
		width: 210px;
	}

	@media(min-width: @screen-sm-min) {
		bottom: 48px;
	}
}

.home-video__video-content {
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.home-video__layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background: #F5F5F5;
	transition: background .4s;

	.loaded & {
		background: transparent;
		display: none;
	}
}

.home-video__buttons {
	position: absolute;
	top: 5px;
	left: 20px;
	z-index: 20;

	@media(min-width: @screen-sm-min) {
		display: block;
		width: 38px;
		right: 70px;
		bottom: 42px;
		top: auto;
		left: auto;
	}
}

.home-video__button {
	.square(38px);
	//border: 1px solid @text-color;
	border-radius: 50%;
	display: block;
	background: @orange;

	.icon {
		.square(38px);
		fill: #fff;
	}

	+ .home-video__button {
		margin-top: 5px;
		
		@media(min-width: @screen-sm-min) {
			margin-top: 15px;
		}
	}

	&.active {
		.icon--default {
			display: none;
		}

		.icon--hover {
			display: inline;
		}
	}
}

// Home search
// -------------------------

.home-search {
	padding: 12px 0;
	background: #fff;
	color: @text-color;

	@media(min-width: @screen-sm-min) {
		padding: 24px 0;
	}
}

.home-search__form {
	display: table;
	width: 100%;

	> div {
		display: table-cell;
		vertical-align: middle;

		&:first-child {
			width: 120px;

			@media(min-width: @screen-sm-min) {
				width: 170px;
			}
		}

		&:last-child {
			width: 20px;
		}
	}

	label {
		.avenir-black();
		font-size: 12px;
		font-weight: 400;
		line-height: 2.93;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		margin: 0;
		position: relative;
		top: 2px;
		cursor: pointer;
		@media(min-width: @screen-sm-min) {
			font-size: 14px;
		}
	}
}

.home-search__search-field {
	.placeholder(#bdbdbd);
	width: 100%;
	font-size: 13px;
	line-height: 1.33;
	border: none;
	padding: 9px 0;
	color: @text-color;
	background: transparent;

	&:focus, &:active {
		outline: 0;
	}
	@media(min-width: @screen-sm-min) {
		font-size: 18px;
	}
}

.home-search__submit {
	width: 20px;
	padding: 0;
	background: transparent;
	border: none;

	.icon-search {
		fill: @text-color;
		width: 19px;
		height: 19.7px;
	}
}

// Home sections
// -------------------------

.home-section {
	padding: 40px 0;
	position: relative;

	@media(min-width: @screen-sm-min) {
		padding: 88px 0;
	}
}

.home-section--fill {
	+ .home-section--fill {
		padding-top: 0;
	}

	@media(min-width: @screen-sm-min) {
		+ .home-section--fill {
			//padding-top: 140px;
			padding-top: 80px;
		}
	}
}

.home-section__mask {
	height: 80px;
	position: absolute;
	top: -79px;
	width: 100%;
	display: none;

	@media(min-width: @screen-sm-min) {
		display: block;
	}
}

// Home actions
// -------------------------

.home-actions {
	@media(min-width: @screen-sm-min) {
		padding-bottom: 176px;
		background-image: url(../../pngs/maskmetschaduw4.png);
		background-position: left bottom -450px;
		background-repeat: repeat-x;
	}
}

.home-actions, .home-projects {
	.lead-text {
		p {
			margin-bottom: 15px;

			@media(min-width: @screen-sm-min) {
				margin-bottom: 30px;
			}
		}
	}
}

.home-actions__text {
	.make-sm-column(3.9);
}

.home-actions__title, .home-projects__title {
	.title--biggest();
	padding-bottom: 12px;
	position: relative;
	color:#fff;
	margin-bottom: 35px;

	&:after {
		content: '';
		width: 70px;
		height: 8px;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

.home-actions__actions {
	.make-sm-column(8.1);

	> .row {
		margin-left: -10px;
		margin-right: -10px;
	}

	.card {

		.make-md-column(6);
		
		padding-left: 10px;
		padding-right: 10px;

		@media(min-width: @screen-md-min) {
			float: right;
			max-width: 344px;
		}
	}
}

.home-actions__more {
	margin-top: 20px;
	float: right;
}

// Home projects
// -------------------------

.home-projects {
	position: relative;

	@media(min-width: @screen-sm-min) {
		// &:before {
		// 	content: '';
		// 	width: 100%;
		// 	height: 279px;
		// 	background-image: url(../../images/pngs/blauw.png);
		// 	background-repeat: repeat-x;
		// 	background-position: center top;
		// 	background-size: 1280px 279px;
		// 	position: absolute;
		// 	top: -85px;
		// 	left: 0;
		// 	pointer-events: none;
		// }
	}
}

.home-projects__text {
	.make-sm-column(3.9);
	.make-sm-column-push(8.1);
}

.home-projects__projects {
	.make-sm-column(8.1);
	.make-sm-column-pull(3.9);

	.card {
		max-width: 668px;
	}
}

.home-projects__more {
	margin-top: 20px;
}

// Hoe werkt het?
// -------------------------

.home-how {
	background: #fff;
}

.home-how__title {
	.title--biggest();
	padding-bottom: 12px;
	position: relative;
	color: @text-color;
	margin-bottom: 60px;
	text-align: center;

	&:after {
		content: '';
		width: 70px;
		height: 8px;
		background-color: @text-color;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -35px;
	}
}

.home-how__items {
	margin-bottom: 30px;

	@media(min-width: @screen-sm-min) {
		margin-bottom: 60px;
		display: flex;
		display: -webkit-flex;
		flex-flow: row nowrap;
		-webkit-flex-flow:row nowrap;
		justify-content: space-between;
		-webkit-justify-content: space-between;
	}
}

// Hoe werkt het? item

.home-how-item {
	text-align: center;
	color: @text-color;
	margin-bottom: 20px;
	
	@media(min-width: @screen-sm-min) {
		max-width: 200px;
		width: 25%;
		margin-bottom: 0;
	}
}

.home-how-item__image-wrapper {
	width: 68px;
	height: 52px;
	margin: 0 auto;
	margin-bottom: 15px;
	position: relative;
	text-align: center;

	@media(min-width: @screen-sm-min) {
		margin-bottom: 30px;
	}
}

.home-how-item__image {
	//margin-bottom: 30px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.home-how-item__title {
	font-size: 21px;
	line-height: 1.24;
	margin-bottom: 20px;
	padding: 0px 20px;
}

.home-how-item__text {
	font-size: 14px;
	line-height: 1.43;
}

.home-how__cta {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
}

// Home stats
// -------------------------

.home-stats {
	background: #fff;
	position: relative;

	@media(min-width: @screen-sm-min) {
		padding-top: 140px;

		&:before {
			content: '';
			width: 100%;
			height: 279px;
			background-image: url(../../pngs/wit.png);
			background-repeat: repeat-x;
			background-position: center top;
			background-size: 1280px 279px;
			position: absolute;
			top: -70px;
			left: 0;
			pointer-events: none;
		}
	}
}

.home-stats__title {
	.title--biggest();
	padding-bottom: 12px;
	position: relative;
	color: @text-color;
	text-align: center;
	max-width: 790px;
	width: 100%;
	margin: 0 auto 60px auto;

	&:after {
		content: '';
		width: 70px;
		height: 8px;
		background-color: @text-color;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -35px;
	}
}

.home-stats__items {
	text-align: center;


	@media(min-width: 480px) {
		display: flex;
		display: -webkit-flex;
		flex-flow: nowrap row;
		-webkit-flex-flow: nowrap row;
		justify-content: center;
		-webkit-justify-content: center;
	}
}

.home-stats-item {
	width: 100%;
	display: inline-block;
	
	@media(min-width: 480px) {
		max-width: 231px;
		width: 33.33%;
		margin: 0 10px;
	}
}

.home-stats-item__thumb {
	width: 100%;
	height: 190px;
	background-size: cover;
	border-radius: 8px;
	margin-bottom: 20px;

	@media(min-width: @screen-sm-min) {
		height: 266px;
	}
}

.home-stats-item__title {
	font-size: 21px;
	line-height: 1.24;
	margin-bottom: 20px;
	padding: 0 15px;
}