.status-message {
	.avenir-black();
	background: #F09508;
	color: #fff;
	font-size: 15px;
	text-align: center;
	padding-top: 16px;
	padding-bottom: 14px;
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;

	@media(min-width: @screen-lg-min) {
		padding-left: @edu-p;
		padding-right: @edu-p;
	}
}