.error404 {
	.footer {
		display: none;
	}
}

html.cssmask .error404 .content-wrapper {
	@media(min-width: @screen-md-min) {
		min-height: calc(100% ~"-" 100px);
		height: calc(100% ~"-" 100px);

		> .container {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
		}
	}
}

.notfound-content {
	background-image: linear-gradient(63deg, #ff9e00, #ff5800 36%, #25bde1 70%, #0067ff);
	padding: 80px 0;
	color: #fff;
	text-align: center;

	.lead-text {
		max-width: 450px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}

.notfound-content__title {
	padding-bottom: 12px;
	position: relative;
	max-width: 300px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	&:after {
		content: '';
		width: 70px;
		height: 7px;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -35px;
	}
}

.notfound-content__back {
	margin-top: 20px;
}