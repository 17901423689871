.tag {
	.avenir-black();
	font-size: 11px;
	text-transform: uppercase;
	color: #fff;
	display: inline-block;
	padding: 4px 9px 2px 9px;
	border: 1px solid #fff;
	border-radius: 4px;
}

.edu-tag {
	overflow: hidden;
	display: inline-block;
	position: relative;

	span {
		.avenir-black();
		font-size: 11px;
		text-transform: uppercase;
		color: #fff;
		padding: 4px 9px 2px 9px;
		border: 1px solid #fff;
		border-radius: 4px;
		display: inline-block;
	}
}

.edu-tag--right {
	@media(min-width: 320px) {
		padding-right: 18px;

		span {
			border-right: 0;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			padding-right: 0;

			&:after, &:before {
				width: 10px;
				height: 50%;
				position: absolute;
				top: 0;
				right: 0;
				border: 1px solid #fff;
				border-left: 0;
				content: '';
			}

			&:before {
				transform: skewX(45deg);
				-webkit-transform: skewX(45deg);
				transform-origin: 100% 100%;
				-webkit-transform-origin: 100% 100%;
				border-bottom: 0;
			}

			&:after {
				top: auto;
				bottom: 0;
				border-top: 0;
				transform: skewX(-45deg);
				-webkit-transform: skewX(-45deg);
				transform-origin: 100% 0%;
				-webkit-transform-origin: 100% 0%;
			}
		}
	}
}

.edu-tag--left {
	@media(min-width: 320px) {
		padding-left: 18px;

		span {
			border-left: 0;
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			padding-left: 0;

			&:after, &:before {
				width: 10px;
				height: 50%;
				position: absolute;
				top: 0;
				left: 0;
				border: 1px solid #fff;
				border-right: 0;
				content: '';
			}

			&:before {
				transform: skewX(-45deg);
				-webkit-transform: skewX(-45deg);
				transform-origin: 100% 100%;
				-webkit-transform-origin: 100% 100%;
				border-bottom: 0;
			}

			&:after {
				top: auto;
				bottom: 0;
				border-top: 0;
				transform: skewX(45deg);
				-webkit-transform: skewX(45deg);
				transform-origin: 100% 0%;
				-webkit-transform-origin: 100% 0%;
			}
		}
	}
}

.edu-tag--inv {
	span {
		border-color: @text-color;
		color: @text-color;

		&:after, &:before {
			border-color: @text-color;
		}
	}
}