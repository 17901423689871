.supporter {
	.clearfix();
	padding: 20px 0;
	border-bottom: 1px solid #fff;
	color: #fff;
	position: relative;

	a {
		color: #fff;
	}

	&:first-child {
		border-top: 1px solid #fff;
	}

	@media(min-width: @screen-md-min) {
		padding: 40px 0;
	}
}

.supporter__header {
	display: table;
	table-layout: fixed;
	width: 100%;

	> div {
		display: table-cell;
		vertical-align: middle;

		&:nth-child(2) {
			text-align: center;
		}

		&:nth-child(3) {
			text-align: right;
		}
	}
}

.supporter__topper {
	.tag();
	.avenir-black();
	padding: 10px 9px 7px 9px;
	font-size: 12px;
	letter-spacing: 0.5px;
	background: #fff;
	color: @text-color;
	position: absolute;
	right: -150px;
	top: 40px;
	width: 90px;
	text-align: center;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	padding-left: 0;

	&:before {
		position: absolute;
		top: -1px;
		left: -16px;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 18px 15px 18px 0;
		border-color: transparent #ffffff transparent transparent;
	}
}

.supporter__title {
	font-size: 17px;
	line-height: 1.12;
	margin-bottom: 4px;
}

.supporter__amount {
	span {
		.avenir-black();
		font-size: 19px;
	}
}

.supporter__date {
	font-size: 12px;
	line-height: 1.58;

	.icon-clock {
		.square(10px);
		margin-right: 4px;
		position: relative;
		top: 1px;
	}
}

.supporter__content {
	font-size: 16px;
	line-height: 1.5;
	margin-top: 10px;

	> *:last-child {
		margin-bottom: 0;
	}
}