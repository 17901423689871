.footer {
	.avenir-heavy();
	background-color: transparent;
	background-image: url(../../images/krijtbord_groot.jpg);
	background-size: cover;
	color: #fff;
	padding-top: 40px;
	padding-bottom: 30px;
	position: relative;

	@media(min-width: @screen-md-min) {
		padding-top: 80px;
		padding-bottom: 40px;
	}
}

html.cssmask .footer {
	@media(min-width: @screen-md-min) {
		margin-top: -95px; // temp
		//mask-repeat: no-repeat;
		//mask-size: 100% auto;
		//mask-position: top center;
		mask: url("../../images/footer_mask-clean.svg#mask");
		-webkit-mask-image: url(../../images/footer_mask-clean.svg);
		padding-top: 120px;
	}
}

.footer__content {
	.clearfix();
	position: relative;
	text-align: center;

	@media(min-width: @screen-md-min) {
		text-align: left;
	}
}

.footer__external-link {
	//.more-link();
	display: none;

	@media(min-width: @screen-md-min) {
		float: left;
		display: inline-block;
	}
}

.footer__logo {
	display: block;
	margin-bottom: 20px;
	//margin-right: -66px;

	@media(min-width: @screen-md-min) {
		display: inline-block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translateY(-50%, -50%);
		margin-bottom: 0;
		//margin-left: -43px;
		margin-right: 0;
	}
}

.footer__logo-img {
	width: 98px;
	height: 51px;
}

.footer-menu {
	display: block;

	@media(min-width: @screen-md-min) {
		float: right;
		display: inline-block;
	}

	ul {
		.reset-list();
	}

	li {
		display: inline-block;
		margin: 0 5px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		@media(max-width: 420px) {
			display: block;
			margin-top: 5px;
			margin-bottom: 5px;
			margin-left: 0;
			margin-right: 0;
		}

		@media(min-width: @screen-md-min) {
			margin: 0 10px;
		}

		&.active {
			a:after {
				width: 100%;
			}
		}
	}

	a {
		color: #fff;
		display: inline-block;
		padding: 8px 0 6px 0;
		line-height: 1;
		background-color: transparent;
		position: relative;

		&:after {
			content: '';
			width: 0;
			height: 2px;
			background-color: #fff;
			position: absolute;
			left: 0;
			bottom: 0;
			transition: width .3s;
		}

		// &:hover {
		// 	&:after {
		// 		width: 100%;
		// 	}
		// }
	}
}

.no-touchevents {
	.footer-menu {
		a {
			&:hover {
				&:after {
					width: 100%;
				}
			}
		}
	}
}

.footer--home {
	//height: 578px; // temp
}

html.cssmask .footer--home {
	margin-top: 0; // temp
	//mask-repeat: no-repeat;
	//mask-size: 100% auto;
	//mask-position: top center;
	mask: none;
	-webkit-mask-image: none;
}

.footer-form {
	color: #fff;
	max-width: 490px;
	width: 100%;
	margin: 0 auto 100px auto;
	text-align: center;

	.form-group {
		text-align: left;
	}

	@media(min-width: @screen-sm-min) {
		.form-group {
			float: left;
			width: 50%;
			padding: 0 10px;
		}
	}

	input[type="email"], input[type="text"] {
		.placeholder(#fff);
		width: 100%;
		font-size: 22px;
		line-height: 0.86;
		border: none;
		padding: 9px 0;
		color: #fff;
		background: transparent;
		opacity: .6;
		transition: opacity .15s;
		border-bottom: 3px solid #fff;

		&:focus, &:active {
			outline: 0;
			opacity: 1;
		}

		@media(min-width: @screen-sm-min) {
			font-size: 25px;
		}
	}
}

.footer-form__title {
	text-align: center;
	//font-size: 55px;
	//line-height: 1.09;
	//letter-spacing: -1.5px;
	position: relative;
	margin-bottom: 20px;
	padding-bottom: 12px;
	font-size: 35px;
	line-height: 1.14;
	letter-spacing: -1px;

	@media(min-width: @screen-md-min) {
		font-size: 55px;
		line-height: 1.09;
		letter-spacing: -1.5px;
		margin-bottom: 40px;
	}

	&:after {
		content: '';
		width: 50px;
		height: 5px;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -25px;

		@media(min-width: @screen-md-min) {
			width: 70px;
			height: 8px;
			margin-left: -35px;
		}
	}
}

.footer-form__submit {
	//.more-link();
	border: none;
	padding: 0;
	background-color: transparent;
	margin-top: 10px;
}

.footer__september {
    font-family: @font-family-sans-serif;
    margin-top: 50px;
    text-align: center;
    font-size: 12px;
    line-height: 2.92;

    a {
        color: #fff;
        text-decoration: underline;

        &:hover {
            opacity: .6;
        }
    }
}