.sponsor {
	.clearfix();
	padding: 20px 0;
	border-bottom: 1px solid #fff;
	color: #fff;
	position: relative;

	a {
		color: #fff;
	}

	&:first-child {
		border-top: 1px solid #fff;
	}

	@media(min-width: @screen-md-min) {
		padding: 40px 0;
	}
}

.sponsor__image {
	.square(92px);
	float: left;
	margin-right: 32px;
	display: none;

	@media(min-width: @screen-sm-min) {
		display: inline;
	}
}

.sponsor__text {
	overflow: hidden;
}

.sponsor__title {
	font-size: 17px;
	line-height: 1.12;
	margin-bottom: 4px;
	padding-right: 100px; // fail safe
}

.sponsor__amount {
	position: absolute;
	right: 0;
	top: 20px;

	span {
		.avenir-black();
		font-size: 19px;
	}

	@media(min-width: @screen-sm-min) {
		top: 30px;
	}
}

.sponsor__date {
	font-size: 12px;
	line-height: 1.58;

	.icon-clock {
		.square(10px);
		margin-right: 4px;
		position: relative;
		top: 1px;
	}
}

.sponsor__content {
	font-size: 16px;
	line-height: 1.5;
	margin-top: 10px;

	> *:last-child {
		margin-bottom: 0;
	}
}