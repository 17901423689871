// General create action styles
// -------------------------

body.create-action {
	background: #f6f6f6;
}

.ca-content {
	background: #f6f6f6;
	padding: 40px 0;

	@media(min-width: @screen-md-min) {
		padding: 80px 0;
	}
}

html.cssmask .ca-content {
	@media(min-width: @screen-md-min) {
		padding-bottom: 160px;
	}
}

// Create action header
// -------------------------

.ca-header {
	.avenir-heavy();
	color: #fff;
	font-size: 14px;
	line-height: 1.71;
	background: #FF5913;
	padding-top: 20px;
	padding-bottom: 20px;
}

.ca-header__back {
	float: left;
	//margin-left: 15px;
	position: relative;
	top: 4px;

	@media(min-width: @screen-sm-min) {
		float: none;
		position: absolute;
		left: @edu-p / 2;
		top: 23px;
	}

	@media(min-width: @screen-lg-min) {
		left: @edu-p;
	}
}

.ca-header__steps {
	.reset-list();
	counter-reset: steps;
	text-align: center;

	li {
		display: inline-block;
		margin: 0 5px;
		position: relative;
		vertical-align: middle;
		counter-increment: steps;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		@media(max-width: 340px) {
			margin: 0 2px;
		}

		@media(min-width: @screen-md-min) {
			margin: 0 15px;
		}

		@media(min-width: @screen-lg-min) {
			margin: 0 20px;
		}

		&.active {
			span:before {
				background: #fff;
				color: #FF5913;
			}
		}
	}

	span {
		color: #fff;
		display: inline-block;
		vertical-align: middle;

		&:before {
			.avenir-black();
			.square(27px);
			border: 1px solid #fff;
			border-radius: 50%;
			content: counter(steps);
			color: #fff;
			font-size: 14px;
			letter-spacing: 1px;
			line-height: 27px;
			text-align: center;
			margin-right: 8px;
			display: inline-block;
			vertical-align: middle;
			background: transparent;
		}

		@media(max-width: @screen-sm-max) {
			.text-hide();

			&:before {
				margin-right: 0;
			}
		}
	}
}

// Create action form
// -------------------------

.ca-form-section {
	max-width: 593px;
	width: 100%;
	margin: 0 auto;
	background: #fff;
	padding: 25px 28px;
	text-align: center;
	position: relative;

	@media(min-width: @screen-sm-min) {
		padding: 50px 100px;
	}
}

.ca-form-section__title {
	padding-bottom: 9px;
	position: relative;
	margin-bottom: 30px;
	font-size: 35px;
	line-height: 1.43;
	letter-spacing: -0.8px;

	&:after {
		content: '';
		width: 70px;
		height: 5px;
		background-color: @text-color;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -35px;
	}
}

.ca-form-section__section-title {
	.avenir-roman();
	font-size: 12px;
	font-weight: 500;
	line-height: 2;
	text-align: center;
	color: @gray;
	position: relative;
	z-index: 1;
	margin: 22px 0;

	&:before {
		border-top: 1px solid @wild-sand;
		content: '';
		margin: 0 auto;
		position: absolute;
		top: 50%; left: 0; right: 0; bottom: 0;
		width: 100%;
		z-index: -1;
	}

	span {
		background: #fff; 
		padding: 0 15px; 
	}
}

.ca-form {
	margin-bottom: 0;

	.textarea-label {
		background: #fff;
	}
}

.ca-form__input-group {
	margin-bottom: 40px;
}

.ca-form__submit {
	margin-top: 20px;
}

.ca-form__submit--login-reg {
	margin-top: 10px;
	width: 100%;
}

.ca-form__forgot-password {
	text-align: left;
	margin-top: -15px;
	margin-bottom: 20px;

	a {
		font-size: 13px;
		line-height: 1.85;
		color: @text-color;
		display: inline-block;
		background: linear-gradient(#fff,#fff),linear-gradient(#fff,#fff),linear-gradient(@text-color,@text-color);
		background-size: .05em 1px,.05em 1px,1px 1px;
		background-repeat: no-repeat,no-repeat,repeat-x;
		text-shadow: .03em 0 #fff,-.03em 0 #fff,0 .03em #fff,0 -.03em #fff,.06em 0 #fff,-.06em 0 #fff,.09em 0 #fff,-.09em 0 #fff,.12em 0 #fff,-.12em 0 #fff,.15em 0 #fff,-.15em 0 #fff;
		background-position: 0 80%,100% 80%,0 80%;
	}
}

.ca-form__register-link {
	display: block;
	margin-top: 20px;
	font-size: 15px;
	line-height: 1.6;
	text-align: center;
	position: absolute;
	top: -50px;
	left: 0;
	width: 100%;
	color: @text-color;

	a {
		.avenir-black();
		color: @ecstasy;

		&:hover, &:active, &:focus {
			text-decoration: underline;
		}
	}

	@media(min-width: @screen-md-min) {
		top: -60px;
	}
}

.ca-form-section__social-media {

}


#slug-preview {
    span {
        color: #FC7613;
    }
}