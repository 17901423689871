// General start action styles
// -------------------------

.start-action-content {
	//.section-padding-top();
	background: @blue;
	padding-bottom: 60px; // temp
	padding-top: 30px;

	@media(min-width: @screen-sm-min) {
		padding-top: 75px;
		padding-bottom: 90px;
	}

	.start-action-header-wrapper > .container-fluid {
		max-width: 574px;
		width: 100%;
		margin: 0 auto;
	}
}

html.cssmask .start-action-content  {
	@media(min-width: @screen-md-min) {
		padding-bottom: 180px;
	}
}

.start-action__submit {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	margin-top: 40px;
}

.start-action__submit--projects {
	float: left;
	clear: both;
}

// Start action header
// -------------------------

.start-action-header-wrapper {
	@media(min-width: @screen-sm-min) {
		padding-bottom: 120px;
		background-image: url(../../pngs/maskmetschaduw4.png);
		background-position: left bottom -450px;
		background-repeat: repeat-x;
	}
}

// .start-action-header-wrapper--projects {
// 	@media(min-width: @screen-sm-min) {
// 		padding-bottom: 120px;
// 	}
// }

.start-action-header {
	text-align: center;
	color: #fff;
}

.start-action__title {
	.title--biggest();
	padding-bottom: 30px;
	position: relative;
	margin-bottom: 30px;

	&:after {
		content: '';
		width: 70px;
		height: 7px;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -35px;
	}
}

.start-action__text-intro {

}

// Start action items
// -------------------------

.start-action__mask {
	height: 80px;
	position: absolute;
	top: -80px;
	width: 100%;
	display: none;

	@media(min-width: @screen-sm-min) {
		display: block;
	}
}

.start-action-items-wrapper {
	position: relative;

	> .container-fluid {
		max-width: 574px;
		width: 100%;
		margin: 0 auto;
	}

	// @media(min-width: @screen-sm-min) {
	// 	position: relative;
	// 	padding-top: 70px;

	// 	&:before {
	// 		content: '';
	// 		width: 100%;
	// 		height: 279px;
	// 		background-image: url(../../images/pngs/blauw.png);
	// 		background-repeat: repeat-x;
	// 		background-position: center top;
	// 		background-size: 1280px 279px;
	// 		position: absolute;
	// 		top: -60px;
	// 		left: 0;
	// 		pointer-events: none;
	// 	}
	// }
}

.start-action-items-wrapper--sm{
	> .container-fluid {
		max-width: 360px;
	}
}

.start-action__items {
	padding: 30px 0 0 0; // temp

	@media(min-width: @screen-sm-min) {
		padding: 60px 0 0 0; // temp
	}
}

.start-action__form {
	label {
		text-transform: none;
		display: block;
		font-weight: 400;
		margin: 0;
		position: relative;

		> input {
			width: 1px;
			height: 1px;
			position: absolute;
			left: 5px;
			top: 5px;
			outline: none;
			border: 1px solid transparent;
			background-color: transparent;
			z-index: -1;
		}
	}

	input:checked + .card {
		.card__selected-overlay {
			opacity: 1;
		}

		.card__title-after {
			width: 100px;
		}

		.card__title:after {
			width: 70px;
		}
	}

	// Wanneer het formulier is veranderd dan moeten niet gecheckte items half doorzichtig worden
	&.changed {
		input:not(:checked) + .card {
			opacity: .6;
		}
	}
}

.start-action__items--projects {
	// .make-row();
	// margin-left: -10px;
	// margin-right: -10px;

	// label {
	// 	.make-md-column(4);
	// 	padding-left: 10px;
	// 	padding-right: 10px;
	// }

	.card {

	}
}