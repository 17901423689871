.image-input-wrapper {
  img {
    max-width: 100%;
  }
  &.empty {
    img {
      display: none;
    }

    .remove {
      display: none;
    }

  }

  .error-message{
    display: none;
  }
  &.error {
    .error-message {
      display: inherit;
    }
  }

  &.form-control {
    height: auto;
  }

  .image-wrapper {
    position: relative;
    .remove {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  input[type="file"] {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.image-input-wrapper {
  background: #f6f6f6;
  border-radius: 8px;
  padding: 0;
  border: none;
  opacity: 1;
}

.image-input__content {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.image-wrapper {
  width: 80px;
  height: 73px;
  background: darken(#f6f6f6, 20%);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: table-cell;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .sk-folding-cube {
    display: none;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -20px;

    .uploading & {
      display: block;
    }
  }

  @media(min-width: @screen-sm-min) {
    width: 120px;
    height: 110px;
  }
}

.image-input__actions {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.image-input__remove-button {
  font-family: 'AvenirLTStd-Heavy', sans-serif;
  display: inline-block;
  color: @text-color;
  border-radius: 8px;
  font-size: 11px;
  padding-top: 8px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: -4px;

  @media(min-width: @screen-sm-min) {
    font-size: 13px;
    padding-top: 16px;
    padding-bottom: 9px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: -8px;
  }
}

.image-input__fake-button {
  font-family: 'AvenirLTStd-Heavy', sans-serif;
  text-transform: none;
  border-radius: 8px;
  font-size: 11px;
  padding-top: 8px;
  padding-bottom: 9px;
  padding-left: 5px;
  padding-right: 5px;

  @media(min-width: @screen-sm-min) {
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.image-input-wrapper--stacked {
  .image-input__content {
    display: block;
  }

  .image-wrapper {
    display: block;
    width: 100%;
    height: 140px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
  }

  .image-input__actions {
    display: block;
    padding: 30px 15px;
  }

  .image-input__remove-button {
    display: block;
  }
}

.sk-folding-cube {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg); }
  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1); }
  .sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
            animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%; }
  .sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
            transform: scale(1.1) rotateZ(90deg); }
  .sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
            transform: scale(1.1) rotateZ(180deg); }
  .sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
            transform: scale(1.1) rotateZ(270deg); }
  .sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s; }
  .sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s; }

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; } }

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; } }