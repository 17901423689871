// Utils

.reset-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.overflow-elipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Font families

.avenir-heavy {
	font-family: 'AvenirLTStd-Heavy', sans-serif;
}

.avenir-roman {
	font-family: 'AvenirLTStd-Roman', sans-serif;
}

.avenir-black {
	font-family: 'AvenirLTStd-Black', sans-serif;
}

// Typography

h1, .h1 {
	font-size: 35px;
	line-height: 1.14;
	position: relative;
	margin-top: 0;
	margin-bottom: 30px;

	@media(min-width: @screen-md-min) {
		font-size: 45px;
		line-height: 1.11;
		margin-bottom: 30px;
	}
}

h2, .h2 {
	margin-top: 0;
}

h3, .h3 {
	margin-top: 0;
}

h4, .h4 {
	margin-top: 0;
}

h5, .h5 {
	margin-top: 0;
}

h6, .h6 {
	margin-top: 0;
}

.after-line {
	padding-bottom: 12px;

	&:after {
		content: '';
		width: 50px;
		height: 5px;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;

		@media(min-width: @screen-md-min) {
			height: 7px;
			width: 70px;
		}
	}
}

.title {
	.title__nr {
		.avenir-roman();
		font-size: 28px;

		@media(min-width: @screen-md-min) {
			font-size: 36px;
		}
	}
}

.title--page-title {
	.title();
	.after-line();
	padding-bottom: 10px;
	color: #fff;
	padding-right: 50px;

	@media(min-width: @screen-md-min) {
		padding-bottom: 15px;
		padding-right: 0;
	}

	&:after {
		height: 5px;

		@media(min-width: @screen-md-min) {
			height: 7px;
		}
	}
}

.title--biggest {
	.title();
	font-size: 35px;
	line-height: 1.14;
	letter-spacing: -1px;

	@media(min-width: @screen-md-min) {
		font-size: 44px;
		line-height: 1.09;
		letter-spacing: -1.5px;
	}

	@media(min-width: @screen-lg-min) {
		font-size: 55px;
	}
}

.title--section-title {
	.title();
	font-size: 20px;
	line-height: 2.05;
	margin-bottom: 24px;

	.title__nr {
		font-size: 15px;
	}
}

.lead-text {
	//font-size: 21px;
	//line-height: 1.48;
	margin-bottom: 20px;
	color: #fff;

	font-size: 16px;
	line-height: 1.5;

	@media(min-width: @screen-md-min) {
		font-size: 19px;
		line-height: 1.38;
	}

	@media(min-width: @screen-lg-min) {
		font-size: 21px;
		line-height: 1.48;
	}
}

.lead-text--invert {
	color: @text-color;
}

.feedback-text {
	font-size: 16px;
	line-height: 1.5;
	text-align: center;

	@media(min-width: @screen-md-min) {
		padding-left: 40px;
		padding-right: 40px;
	}
}

// Buttons

.button {
	.avenir-black();
	display: inline-block;
	text-align: center;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.button-square {
	.button();
	color: #fff;
	font-size: 15px;
	padding: 15px 26px 13px 26px;
	background: @ecstasy;
	border: 2px solid @ecstasy;
	border-radius: 0;
	transition: background .15s, border .15s, color .3s;
	line-height: 1;

	&:hover, &:focus, &:active {
		//background: transparent;
		background: #f16600;
		border: 2px solid #f16600;
		//color: @ecstasy;
		color: #fff;
		outline: 0;
	}
}

.button-square--invert {
	background: transparent;
	box-shadow: inset 0px 0px 0px 2px #fff;
	border: none;
	padding: 17px 26px 15px 26px;

	&:hover, &:focus, &:active {
		background: transparent;
		color: #fff;
		border: none;
		box-shadow: inset 0px 0px 0px 3px #fff;
	}
}

.button-rounded {
	.button();
	border-radius: 100px;
	color: #fff;
	background: @blue-light;
	padding: 14px 40px;
	transition: background .15s, color .3s;
	border: none;

	&:focus, &:active {
		background: #009ad5;
		color: #fff;
		outline: 0;
	}

	.icon {
		.square(16px);
		margin-left: 3px;
		position: relative;
		top: 3px;
	}
}

.no-touchevents {
	.button-rounded {
		&:hover, &:focus, &:active {
			background: #009ad5;
			color: #fff;
			outline: 0;

			.icon--default {
				display: none;
			}

			.icon--hover {
				display: inline;
			}
		}
	}
}

.button-dulled {
	.button();
	border-radius: 6px;
	border: 2px solid @text-color;
	color: @text-color;
	background: transparent;
	padding: 20px;
	transition: background .15s, border .15s, color .3s;

	&:hover, &:focus, &:active {
		background: @blue-light;
		border-color: @blue-light;
		color: #fff;
		outline: 0;
	}
}

.no-touchevents {
	.button-dulled {
		&:hover, &:focus, &:active {
			background: @blue-light;
			border-color: @blue-light;
			color: #fff;
			outline: 0;
		}
	}
}

.button-social-media {
	.button-square();
	color: #fff;
	font-size: 13px;
	letter-spacing: 1px;
	text-align: center;
	text-transform: uppercase;
	padding: 14px 20px 12px 20px;
	margin-left: 0;
	margin-right: 0;

	svg {
		margin-right: 10px;
		vertical-align: middle;
		position: relative;
		top: -2px;
	}

	.button-social-media__icon {
		transition: fill .3s;
	}
}

.button-social-media--full {
	width: 100%;

	+ .button-social-media--full {
		margin-top: 20px;
	}
}

.button-facebook {
	background: @facebook;
	border-color: @facebook;

	&:hover, &:focus, &:active {
		background: #254492;
		border-color: #254492;
		color: #fff;
		outline: 0;
	}
}

.button-google {
	background: @google;
	border-color: @google;

	&:hover, &:focus, &:active {
		background: #da3823;
		border-color: #da3823;
		color: #fff;
		outline: 0;
	}
}

.button-follow-action {
	.button-dulled();
	border-width: 1px;
	border-color: #fff;
	padding: 15px;
	color: #fff;
	border: none;
	box-shadow: inset 0px 0px 0px 1px #fff;

	&:focus, &:active {
		background: transparent;
		color: #fff;
		box-shadow: inset 0px 0px 0px 1px #fff;
		outline: 0;

		.icon--default {
			display: inline;
		}

		.icon--hover {
			display: none;
		}
	}

	// &:focus, &:active {
	// 	outline: 0;
	// 	box-shadow: inset 0px 0px 0px 1px #fff;

	// 	.icon--default {
	// 		display: inline;
	// 	}

	// 	.icon--hover {
	// 		display: none;
	// 	}
	// }

	.icon {
		.square(16px);
		margin-left: 3px;
		position: relative;
		top: 3px;
	}
}

.no-touchevents {
	.button-follow-action {
		&:hover, &:focus, &:active {
			background: transparent;
			color: #fff;
			box-shadow: inset 0px 0px 0px 2px #fff;
			outline: 0;

			.icon--default {
				display: none;
			}

			.icon--hover {
				display: inline;
			}
		}

		&:focus, &:active {
			outline: 0;
			box-shadow: inset 0px 0px 0px 1px #fff;

			.icon--default {
				display: inline;
			}

			.icon--hover {
				display: none;
			}
		}
	}
}

.button-remove {
	.avenir-heavy();
	color: @text-color;
	font-size: 14px;
	line-height: 1.7;

	.icon {
		.square(24px);
		fill: @text-color;
		margin-right: 10px;
		position: relative;
		vertical-align: middle;
		top: -1px;
	}

	&:hover, &:focus, &:active {
		outline: 0;
		color: @text-color;

		.icon--default {
			display: none;
		}

		.icon--hover {
			display: inline;
		}
	}
}

.more-link {
	.avenir-black();
	display: inline-block;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 14px;
	color: #fff;

	// &:hover, &:focus, &:active {
	// 	color: #fff;
	// 	outline: 0;

	// 	.icon--default {
	// 		display: none;
	// 	}

	// 	.icon--hover {
	// 		display: inline;
	// 	}
	// }

	.icon {
		.square(16px);
		margin-left: 3px;
		position: relative;
		top: 3px;
	}
}

.more-link--bottom {
	margin-bottom: 20px;
}

.button--white {
	.avenir-heavy;
	font-size: 16px;
	color: @text-color;
	background-color: #fff;
	box-shadow: 0 15px 40px 0 rgba(229, 209, 194, 0.15), 0 -5px 40px 0 rgba(229, 209, 194, 0.15);

	&:focus,
	&:hover {
		color: @text-color;
		background-color: #fff;
		box-shadow: 0 15px 40px 0 rgba(229, 209, 194, 0.25), 0 -5px 40px 0 rgba(229, 209, 194, 0.25);
	}
}

.button--int {
	img {
		margin-left: 10px;
		width: 14px;
		height: 10px;
	}
}

.more-link--unfollow {
	display: block;
	margin-top: 10px;
	font-size: 12px;

	.icon {
		width: 14px;
    	height: 14px;
	}

	@media(min-width: @screen-md-min) {
		text-align: center;
	}
}

.more-link--invert {
	.more-link();
	color: @text-color;

	.icon {
		fill: @text-color;
	}

	// &:hover, &:focus, &:active {
	// 	color: @text-color;
	// }
}

.no-touchevents {
	.more-link {
		&:hover, &:focus, &:active {
			color: #fff;
			outline: 0;

			.icon--default {
				display: none;
			}

			.icon--hover {
				display: inline;
			}
		}
	}

	.more-link--invert {
		&:hover, &:focus, &:active {
			color: @text-color;
			outline: 0;

			.icon--default {
				display: none;
			}

			.icon--hover {
				display: inline;
			}
		}
	}
}

.more-link--reverse {
	.icon {
		margin-left: 0;
		margin-right: 3px;
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
	}
}

.more-link--external {
	.icon {
		.square(18px);
	}
}

.more-link--small {
	font-size: 11px;

	.icon {
		.square(12px);
		top: 2px;
	}
}

// Scaffolding

.content-wrapper {
	@media(min-width: @screen-md-min) {
		min-height: calc(100% ~"-" 268px); // LOL
	}
}

html.cssmask .content-wrapper {
	@media(min-width: @screen-md-min) {
		min-height: calc(100% ~"-" 218px); // LOL
	}
}

.section-padding-top {
	padding-top: @section-padding-top;

	@media(min-width: @screen-sm-min) {
		padding-top: @section-padding-top-sm;
	}
}