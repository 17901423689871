@edu-p: 50px; // padding horizontal

@edu-p-v: 35px; // padding vertical

@edu-content-p-b: 120px; // content padding bottom
@edu-content-p-b_mobile: 40px; // content padding bottom mobile

@edu-desktop: 1480px;

@edu-header-height: 100px;
@edu-header-height-m: 60px;

// General edu grid styles
// -------------------------

.edu-container {
	// Use flexbox to reorder columns on mobile with order property
	// display: flex;
	// flex-direction: column;
	// align-items: flex-start;

	@media(min-width: @screen-md-min) {
		display: table;
		table-layout: fixed; // ?
		width: 100%;
	}
}

.edu-container--uploading{
	.edu-ep-sidebar{
		input[type="submit"]{
			pointer-events: none;
			filter: grayscale(100%);
		}
	}
}

// Header
// -------------------------

.edu-header, .edu-footer, .edu-full {
	position: relative;
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;

	@media(min-width: @screen-lg-min) {
		padding-left: @edu-p;
		padding-right: @edu-p;
	}
}

// Left column
// -------------------------

.edu-menu {
	//order: 1;
	//width: 100%;

	.action-menu-active & {
		padding-bottom: @edu-content-p-b_mobile;
	}

	@media(min-width: @screen-md-min) {
		display: table-cell;
		vertical-align: top;
		width: 242px;
		padding-top: @edu-p-v;
		padding-bottom: @edu-content-p-b;
	}

	@media(min-width: @screen-lg-min) {
		width: 292px;
	}
}

// Action menu column
.edu-menu--action {
	background: @web-orange;
}

// Page menu column
.edu-menu--page {
	background: @congress-blue;
}

.edu-menu-wrapper {
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;

	@media(min-width: @screen-lg-min) {
		padding-left: @edu-p;
		padding-right: @edu-p;
	}
}

.edu-menu-wrapper--sidebar {
	@media(max-width: @screen-sm-max) {
		padding-top: @edu-p / 2;
	}
}

// Center column
// -------------------------

.edu-content {
	//order: 3;
	//width: 100%;

	@media(min-width: @screen-md-min) {
		display: table-cell;
		vertical-align: top;
		width: auto;
	}
}

html.cssmask .edu-content {
	@media(min-width: @screen-md-min) {
		padding-bottom: 90px;
	}
}

.edu-content-wrapper {
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;
	padding-top: @edu-p / 2;
	padding-bottom: @edu-content-p-b_mobile;

	@media(min-width: @screen-sm-min) {

	}

	@media(min-width: @screen-md-min) {
		padding-left: @edu-p / 2;
		padding-right: 60px;
		padding-top: @edu-p-v;
		padding-bottom: @edu-content-p-b;
		max-width: 735px;
		width: 100%;
	}

	@media(min-width: @screen-lg-min) {
		padding-left: 70px;
		padding-right: 70px;
	}

	@media(min-width: @edu-desktop) {
		padding-left: 120px;
	}
}

.edu-content-wrapper--cropped {
	@media(min-width: @screen-md-min) {
		max-width: 492px;
	}

	@media(min-width: @screen-lg-min) {
		max-width: 735px;
	}
}

.edu-content-wrapper--page {
	padding-top: @edu-p / 2;

	@media(min-width: @screen-md-min) {
		padding-top: @edu-p;
	}
}

// Right column
// -------------------------

.edu-sidebar {
	background: #fff;
	padding-bottom: @edu-content-p-b_mobile;
	//width: 100%;
	//order: 2;

	@media(min-width: @screen-md-min) {
		display: table-cell;
		vertical-align: top;
		padding-bottom: @edu-content-p-b;
		width: 290px;
	}

	@media(min-width: @screen-lg-min) {
		width: 320px
	}

	@media(min-width: @edu-desktop) {
		width: 420px;
	}
}

// Edit post
// -------------------------

.edu-ep-content-wrapper {
	width: 100%;
	padding-bottom: 0;
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;
	padding-top: @edu-p-v / 2;


	@media(min-width: @screen-md-min) {
		max-width: 560px;
		margin: 0 auto;
		padding-bottom: @edu-content-p-b;
		padding-top: @edu-p-v;
	}
}

.edu-ep-sidebar {
	padding-top: @edu-p-v / 2;
	padding-bottom: 0;
	padding-left: @edu-p / 2;
	padding-right: @edu-p / 2;

	&:last-child {
		padding-bottom: @edu-p-v / 2;
	}

	@media(min-width: @screen-md-min) {
		display: table-cell;
		vertical-align: top;
		width: 320px;
		padding-left: @edu-p;
		padding-right: @edu-p;
		padding-bottom: @edu-content-p-b;
		padding-top: @edu-p-v;

		&:last-child {
			padding-bottom: 0;
		}
	}
}

.edu-ep-sidebar--full {
	@media(min-width: @screen-md-min) {
		width: 320px;
		padding-left: @edu-p;
		padding-right: 0;
	}
}

.edu-ep-sidebar--fill {
	@media(min-width: @screen-md-min) {
		background: @wild-sand;
	}
}