.password-score {
	width: 100%;
	display: flex;
	display: -webkit-flex;
	flex-flow: row nowrap;
	-wekbit-flex-flow: row nowrap;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	margin-bottom: 20px;

	> div {
		flex: 0 1 19%;
		-webkit-flex: 0 1 19%;
		height: 6px;
		background: #c3c3c3;
		transition: background .15s;
	}

	&.score0 > div:nth-child(1) {
		background: red;
	}

	&.score1 > div:nth-child(1), &.score1 > div:nth-child(2) {
		background: yellow;
	}

	&.score2 > div:nth-child(1), &.score2 > div:nth-child(2), &.score2 > div:nth-child(3) {
		background: orange;
	}

	&.score3 > div:nth-child(1), &.score3 > div:nth-child(2), &.score3 > div:nth-child(3), &.score3 > div:nth-child(4) {
		background: green;
	}

	&.score4 > div:nth-child(1), &.score4 > div:nth-child(2), &.score4 > div:nth-child(3), &.score4 > div:nth-child(4), &.score4 > div:nth-child(5) {
		background: green;
	}
}