.comment {
	padding: 20px 0;
	border-bottom: 1px solid #fff;
	position: relative;

	&:first-child {
		border-top: 1px solid #fff;
	}

	@media(min-width: @screen-sm-min) {
		margin-right: 300px;
	}

	@media(min-width: @screen-lg-min) {
		margin-right: 200px;
	}

	@media(min-width: 1268px) {
		margin-right: 0;
	}
}

.comment__report {
	width: 256px;
	background: #fff;
	color: @text-color;
	padding: 15px 30px;
	border-radius: 6px;
	position: absolute;
	top: 20px;
	right: 30px;
	visibility: hidden;
	opacity: 0;
	transform: translateX(50px);
	-webkit-transform: translateX(50px);
	transition: opacity .15s, visibility .15s, transform .4s, -webkit-transform .4s;

	.report-open & {
		opacity: 1;
		visibility: visible;
		transform: translateX(0);
		-webkit-transform: translateX(0);
		transition: opacity .6s, visibility .15s, transform .25s, -webkit-transform .25s;
	}

	label {
		.avenir-roman();
		cursor: pointer;
		text-transform: none;
		font-size: 14px;
		font-weight: 400;

		input {
			display: none;

			&:checked {
				+ span {
					opacity: 1;
					border-color: #fff;

					.icon--hover {
						.square(18px);
						display: block;
						fill: @text-color;
						position: absolute;
						top: -2px;
						left: -2px;
					}
				}
			}
		}

		> span {
			.square(18px);
			border: 2px solid @text-color;
			background: #fff;
			border-radius: 50%;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			top: -1px;
			margin-right: 10px;
			opacity: .4;
			transition: opacity .15s;
		}
	}

	button[type="submit"] {
		//.more-link--invert();
		border: none;
		background: transparent;
		padding: 0;
		margin: 20px 0 6px 0;
	}

	@media(min-width: @screen-sm-min) {
		right: -300px;
	}
}

.comment__report-heading {
	.title();
	font-size: 20px;
	line-height: 2.05;
	margin: 0 0 6px 0;
}

.comment__header {
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 15px;
	position: relative;
	padding-right: 30px;
}

.comment__flag {
	//.square(26px); // temp
	//border-radius: 50%;
	//border: 1px solid #fff;
	//background: transparent;
	position: absolute;
	right: 0;
	top: 0;

	.report-open & {
		//background: #fff;
	}

	&:hover, .report-open & {
		.icon--default {
			display: none;
		}

		.icon--hover {
			display: inline;
		}
	}

	.icon {
		.square(24px);
	}

	.icon-report_hover {
		width: 28px;
		position: absolute;
		right: -4px;
	}
}

.comment__img {
	.square(34px);
	display: inline-block;
	border-radius: 50%;
	margin-right: 5px;
}

.comment__name {
	.avenir-black();
	display: inline-block;
}

.comment__date {
	display: inline-block;
	font-size: 12px;
	padding-left: 3px;

	&:before {
		content: '-';
		margin-right: 5px;
	}
}

.comment__content {
	font-size: 16px;
	line-height: 1.5;
}