.create-post-content {
	padding: 50px 0;
}

.create-post-header {
	.avenir-heavy();
	color: #fff;
	font-size: 14px;
	line-height: 1.71;
	background: @web-orange;
	padding-top: 20px;
	padding-bottom: 20px;

	a {
		color: #fff;
	}
}

.cp-form {
	.textarea-label {
		background-color: #fff;
	}
}

.cp-form__heading {
	font-size: 20px;
	line-height: 2.05;
	margin-bottom: 15px;

	span {
		.avenir-roman();
	}

	@media(min-width: @screen-md-min) {
		margin-bottom: 30px;
	}
}

.cp-form__expl {
	padding-top: 15px;
	border-top: 1px solid rgba(26, 25, 25, 0.1);
	font-size: 11px;
	font-weight: 500;
	line-height: 1.4;
	color: #888888;
	margin-top: 25px;

	p {
		margin-bottom: 0;
	}
}

.cp-form__image-upload {
	label {
		display: none;
	}
}

.cp-form__submit {
	margin-top: 40px;
	margin-bottom: 30px;
	display: block;
}