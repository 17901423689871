.update {
	color: #fff;

	@media(min-width: @screen-md-min) {
		margin-top: -@edu-p-v;
	}
}

.update__sharing {
	position: absolute;
	right: 0px;
	top: -25px;
	z-index: 10;

	@media(min-width: @screen-md-min) {
		right: -60px;
		top: 30px;
		z-index: auto;
	}
}

.update__top {
	display: flex;
	display: -webkit-flex;
	align-items: flex-start;
	-webkit-align-items: flex-start;
	flex-direction: column;
	-webkit-flex-direction: column;
	width: 100%;
	margin-bottom: 15px;

	@media(min-width: @screen-md-min) {
		display: block;
		margin-bottom: 0;
	}
}

.update__head {
	width: 100%;
	order: 2;
	-webkit-order: 2;

	@media(min-width: @screen-md-min) {
		display: block;
		width: auto;
	}

	// @media(min-width: @screen-md-min) {
	// 	margin-left: -@edu-p / 2;
	// }

	// @media(min-width: @screen-lg-min) {
	// 	margin-left: -70px;
	// }

	// @media(min-width: @edu-desktop) {
	// 	margin-left: -120px;
	// }
}

.update__header {
	order: 1;
	-webkit-order: 1;
	width: 100%;

	@media(min-width: @screen-md-min) {
		display: block;
	}
}

.update__header-image {
	.img-responsive();
	width: 100%;
}

.update__meta {
	margin-top: 15px;
	margin-bottom: 15px;
	position: relative;

	@media(min-width: @screen-md-min) {
		margin-top: 38px;
	}
}

.update__date {
	font-size: 14px;
	line-height: 1.36;
	color: #fff;

	.icon-clock {
		.square(12px);
		margin-right: 3px;
		position: relative;
		top: 1px;
	}
}

.update__edit {
	.tag();
	.avenir-heavy();
	font-size: 10px;
	line-height: 2;
	padding: 1px 9px 0px 9px;
	margin-left: 10px;

	&:hover, &:active, &:focus {
		color: #fff;
	}

	.icon-edit-small {
		.square(10px);
		margin-left: 5px;
		position: relative;
		top: 2px;
	}
}

.update__edit--page {
	margin-left: 0;
	margin-bottom: 12px;
}

.update__edit--reporter {
	margin-left: 0;
	margin-top: 12px;
}

.update__media {
	margin-bottom: 50px;
}

.update__comments {
	margin-bottom: 50px;

	> ul {
		.reset-list();	
	}
}

.update__comment {
	margin-bottom: 50px;
}