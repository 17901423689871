// TODO: FLEX BOX FALLBACK MAKEN!!!

// Card default styling
// -------------------------

.card {
	margin-bottom: 20px;

	&:hover, &:focus, &:active {
		.card__overlay {
			background-color: rgba(0, 0, 0, .25);
		}

		.card__sharing {
			opacity: 1;
			transform: translateX(0);
			-webkit-transform: translateX(0);
			transition: transform .15s, opacity .5s;
			-webkit-transition: -webkit-transform .15s, opacity .5s;
		}
	}
	@media(min-width: @screen-sm-min) {
		margin-bottom: 30px;
	}
}

.card__content {
	background: #fff;
	border-radius: 8px;
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
	position: relative;

	//overflow: hidden;
}

.card__type {

}

.card__image {
	height: 195px;
	width: 100%;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	overflow: hidden;

	img {
		object-fit: cover;
		object-position: center;
		height: 100%;
		width: 100%;
	}
}

.card__text {
	padding: 5px 33px;
	background: #fff;
}

.card__title {
	font-size: 30px;
	line-height: 1.13;
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 15px;

	a {
		color: @text-color;
		display: block;
	}

	&:after {
		content: '';
		width: 50px;
		height: 4px;
		background-color: @web-orange;
		position: absolute;
		bottom: 0;
		left: 0;
		transition: width .15s;
	}

	&:hover, &:focus, &:active {
		&:after {
			width: 100px;
		}
	}
}

.card__description {
	.avenir-roman();
	font-size: 14px;
	font-weight: 400;
	line-height: 1.43;

	p {
		margin-bottom: 0;
	}
}

.card__visual {
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #f4f4f4;
	background-position: center center;
	position: relative;
	overflow: hidden;

	&.b-lazy {
		&:after {
			content: '';
			background-color: #f4f4f4;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: opacity .5s;
			pointer-events: none;

			.card--no-image & {
				display: none;
			}
		}
	}

	&.b-lazy.b-loaded {
		&:after {
			opacity: 0;
		}
	}
}

.card__meta {
	font-size: 12px;
	line-height: 1.58;
}

.card__date {

}

.card__comments {
	//margin-left: 20px;
}

.card__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color .25s;
	cursor: pointer;
}

.card__edit {
	//.square(26px); // temp
	//border-radius: 50%;
	//border: 1px solid #fff;
	background: transparent;

	.icon {
		.square(24px);
	}

	&:hover {
		.icon--default {
			display: none;
		}

		.icon--hover {
			display: block;
		}
	}
}

.card__sharing {
	position: absolute;
	bottom: 30px;
	left: 30px;
	transform: translateX(-135px);
	-webkit-transform: translateX(-135px);
	opacity: 0;
	transition: transform .5s, opacity .15s;
	-webkit-transition: -webkit-transform .5s, opacity .15s;
}

.card__theme {
	.tag();
	color: #fff;
	margin-bottom: 10px;
	display: inline-block;
}

.card--no-image {
	.card__visual {
		background-size: auto !important;
		background-image: url(../../images/logo-light-gray.svg) !important;
		background-position: center center;
	}
}

// Update card
// -------------------------

.card--update {
	margin-bottom: 20px;

	&:hover, &:focus, &:active {
		.card__overlay {
			background-color: rgba(0, 0, 0, .25);
		}

		.card__sharing {
			opacity: 1;
			transform: translateX(0);
			-webkit-transform: translateX(0);
			transition: transform .15s, opacity .5s;
			-webkit-transition: transform .15s, opacity .5s;
		}
	}

	.card__content {
		background: #fff;
		border-radius: 8px;
		display: flex;
		display: -webkit-flex;
		flex-flow: row wrap;
		-webkit-flex-flow: row wrap;
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		position: relative;

		@media(min-width: @screen-sm-min) {
			flex-flow: row nowrap;
		}

		@media(min-width: @screen-lg-min) {
			//min-height: 216px;
		}
	}

	.card__edit {
		//.square(26px); // temp
		//border-radius: 50%;
		//border: 1px solid #fff;
		//background: transparent;
		position: absolute;
		top: 0;
		right: -35px;
	}

	.card__text {
		padding: 20px 20px;
		width: 100%;
		
		//flex-flow: column nowrap;
		//justify-content: center;
		background: #fff;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		order: 2;
		-webkit-order: 2;

		@media(min-width: @screen-sm-min) {
			width: 50%;
			display: flex;
			display: -webkit-flex;
			flex-flow: column nowrap;
			-webkit-flex-flow: column nowrap;
			justify-content: center;
			-webkit-justify-content: center;
			order: 0;
			-webkit-order: 0;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}

		@media(min-width: @screen-lg-min) {
			padding: 33px 33px;
		}
	}

	.card__visual {
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		overflow: hidden;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		order: 1;
		-webkit-order: 1;
		height: 150px;

		@media(min-width: @screen-sm-min) {
			width: 50%;
			order: 0;
			-webkit-order: 0;
			height: auto;
			border-top-left-radius: 0px;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}

	.card__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0);
		transition: background-color .25s;
	}

	.card__sharing {
		position: absolute;
		bottom: 30px;
		left: 30px;
		transform: translateX(-135px);
		-webkit-transform: translateX(-135px);
		opacity: 0;
		transition: transform .5s, opacity .15s;
		-webkit-transition: transform .5s, opacity .15s;
	}

	.card__group-update {
		.avenir-black();
		font-size: 12px;
		line-height: 1.58;
		color: #fff;
		background-color: @text-color;
		padding: 9px 12px 7px 12px;
		position: absolute;
		right: 0;
		top: 0;
		border-bottom-left-radius: 8px;
		text-transform: uppercase;
	}

	.card__type {
		// font-size: 12px;
		// line-height: 1.58;
		// margin-bottom: 15px;
		// display: block;

		margin-left: 20px;

		.icon-updates {
			.square(14px);
			fill: @text-color;
			margin-right: 4px;
			position: relative;
			top: 2px;
		}

		@media(min-width: @screen-md-min) {
			margin-left: 0;
			float: left;
			clear: both;
		}

		@media(min-width: @screen-lg-min) {
			margin-left: 20px;
			float: none;
		}

		// @media(min-width: @screen-md-min) {
		// 	margin-bottom: 10px;
		// }

		// @media(min-width: @screen-lg-min) {
		// 	margin-bottom: 15px;
		// }
	}

	.card__type--vlog{
		margin-left: 0;
		margin-bottom: 10px;
		display: block;
	}

	.card__owner {
		font-size: 12px;
		line-height: 1.58;
		margin-bottom: 15px;
		display: block;

		@media(min-width: @screen-md-min) {
			margin-bottom: 10px;
		}

		@media(min-width: @screen-lg-min) {
			margin-bottom: 15px;
		}
	}

	.card__owner-img {
		.square(27px);
		display: inline-block;
		vertical-align: middle;
		border-radius: 50%;
		margin-right: 6px;
		position: relative;
		top: -1px;
	}

	.card__owner-name {
		.avenir-heavy();
	}

	.card__title-after {
		width: 50px;
		height: 4px;
		position: absolute;
		bottom: 0;
		left: 0;
		transition: width .15s;
	}

	.card__title {
		font-size: 30px;
		line-height: 1.13;
		padding-bottom: 10px;
		margin-bottom: 15px;

		@media(min-width: @screen-md-min) {
			font-size: 24px;
			line-height: 1.13;
			padding-bottom: 10px;
			margin-bottom: 12px;
		}

		@media(min-width: @screen-lg-min) {
			font-size: 30px;
			line-height: 1.13;
			padding-bottom: 10px;
			margin-bottom: 15px;
		}

		&:after {
			background-color: @web-orange;
			display: none;
		}

		// &:hover, &:focus, &:active {
		// 	&:after {
		// 		width: 100px;
		// 	}
		// }

		&:hover, &:focus, &:active {
			.card__title-after {
				width: 100px;
			}
		}
	}

	.card__meta {
		font-size: 12px;
		line-height: 1.58;
		overflow: hidden;
	}

	.card_date {
		.icon-clock {
			.square(10px);
			fill: @text-color;
			margin-right: 2px;
			position: relative;
			top: 1px;
		}

		@media(min-width: @screen-md-min) {
			float: left;
			clear: both;
		}

		@media(min-width: @screen-lg-min) {
			float: none;
		}
	}

	.card__comments {
		margin-left: 20px;

		.icon-comment {
			width: 12px;
			height: 10px;
			fill: @text-color;
			margin-right: 2px;
			position: relative;
			top: 1px;
		}

		@media(min-width: @screen-md-min) {
			float: left;
			clear: both;
			margin-left: 0;
		}

		@media(min-width: @screen-lg-min) {
			float: none;
			margin-left: 20px;
		}
	}
}

.card--update--vlog{
	.playbutton-wrapper{
		display: flex;
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.icon.icon-playbutton{
		width: 50px;
		height: 50px;
	}
}

// Owner card
// -------------------------

.card--owner {
	padding-right: 20px;

	@media(min-width: @screen-md-min) {
		padding-right: 0;
	}
}

// Action card
// -------------------------

.card--action {
	.card__content {

	}

	.card__visual {
		height: 193px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.card__text {
		padding: 38px 33px;
		background: #fff;
		border-radius: 8px;
	}

	.card__title-wrapper{
		min-height: 65px;
	}

	.card__title {
		font-size: 21px;
		line-height: 1.24;
		margin-bottom: 15px;
		&:hover, &:focus, &:active {
			.card__title-after {
				width: 100px;
			}
		}
	}

	.card__title-after {
		width: 50px;
		height: 4px;
		position: absolute;
		bottom: 0;
		left: 0;
		transition: width .15s;
	}

	.card__description {

	}

	.card__amounts {
		.avenir-black();
		color: @text-color;
		display: table;
		width: 100%;
		margin-top: 15px;
	}

	.card__current-amount, .card__target-amount {
		display: table-cell;
		vertical-align: baseline;
		width: 50%;
	}

	.card__current-amount {
		font-size: 19px;
		text-align: left;
	}

	.card__target-amount {
		font-size: 15px;
		text-align: right;
	}

	.card__prog-bar {
		padding-bottom: 33px;
	}

	.prog-bar {
		background: @gray-lighter;
	}

	.card__meta {
		margin-top: 10px;
	}
}

.card--action-condensed {
	.card__visual {
		height: 162px;
	}

	.card__text {
		padding: 20px 33px;
	}
}

.card--action-minimal {
	.card__prog-bar {
		display: none;
	}

	.card__title {
		&:after {
			background-color: #0076ff;
		}
	}
}

.card--dyn-colors {
	.card__title {
		&:after {
			display: none;
		}
	}
}

// Selectable card
// -------------------------

.card--selectable {
	cursor: pointer;
	transition: opacity .25s;

	&:hover, &:focus, &:active {
		opacity: 1 !important;

		.card__overlay {
			background-color: rgba(0, 0, 0, .25);
		}
	}

	.card__visual {
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
		-webkit-transform: translate3d(0, 0, 0);
	}

	.card__selected-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity .25s;

		span {
			border: 1px solid #fff;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
		}

		.icon-check {
			position: absolute;
			top: 50%;
			left: 50%;
		}
	}
}

// Theme card
// -------------------------

.card--theme {
	margin-bottom: 20px;

	&:hover, &:focus, &:active {
		.card__overlay {
			background-color: rgba(0, 0, 0, .25);
		}

		.card__sharing {
			opacity: 1;
			transform: translateX(0);
			-webkit-transform: translateX(0);
			transition: transform .15s, opacity .5s;
			-webkit-transition: -webkit-transform .5s, opacity .15s;
		}
	}

	.card__content {
		background: #fff;
		border-radius: 8px;
		//min-height: 283px;
		display: flex;
		display: -webkit-flex;
		flex-flow: row wrap;
		-webkit-flex-flow:row wrap;
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		position: relative;

		@media(min-width: @screen-sm-min) {
			flex-flow: row nowrap;
			-webkit-flex-flow:row nowrap;
			//min-height: 283px;
		}
	}

	.card__edit {
		.square(26px); // temp
		border-radius: 50%;
		border: 1px solid #fff;
		background: transparent;
		position: absolute;
		top: 0;
		right: -35px;
	}

	.card__text {
		padding: 20px 20px;
		width: 100%;
		//display: flex;
		//flex-flow: column nowrap;
		//justify-content: center;
		//align-items: flex-start;
		background: #fff;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		order: 2;
		-webkit-order: 2;

		@media(min-width: @screen-sm-min) {
			width: 50%;
			// display: flex;
			// flex-flow: column nowrap;
			// justify-content: center;
			// align-items: flex-start;
			order: 0;
			-webkit-order: 0;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}

		@media(min-width: @screen-lg-min) {
			padding: 40px 40px;
		}
	}

	.card__description {

	}

	.card__visual {
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		overflow: hidden;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		order: 1;
		-webkit-order: 1;
		height: 150px;

		@media(min-width: @screen-sm-min) {
			width: 50%;
			order: 0;
			-webkit-order: 0;
			height: auto;
			border-top-left-radius: 0px;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}

	.card__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0);
		transition: background-color .25s;
	}

	.card__sharing {
		position: absolute;
		bottom: 30px;
		left: 30px;
		transform: translateX(-135px);
		-webkit-transform: translateX(-135px);
		opacity: 0;
		transition: transform .5s, opacity .15s;
		-webkit-transition: -webkit-transform .5s, opacity .15s;
	}

	.card__title {
		font-size: 21px;
		line-height: 1.24;
		padding-bottom: 10px;
		margin-bottom: 15px;

		a {
			color: @text-color;
			display: block;
		}

		&:after {
			display: none;
		}

		&:hover, &:focus, &:active {
			.card__title-after {
				width: 100px;
			}
		}
	}

	.card__title-after {
		width: 50px;
		height: 4px;
		position: absolute;
		bottom: 0;
		left: 0;
		transition: width .15s;
	}

	.card__current-projects {
		margin-top: 20px;
		display: table;
		width: 100%;

		> div {
			display: table-cell;
			vertical-align: middle;

			&:first-child {
				width: 30px;
			}
		}
	}

	.card__projects-nr {
		.avenir-black();
		height: 30px;
		min-width: 30px;
		padding-left: 5px;
    	padding-right: 5px;
		border-radius: 14px;
		display: inline-block;
		vertical-align: middle;
		color: #fff;
		font-size: 16px;
		line-height: 33px;
		text-align: center;
	}

	.card__current-projects-text {
		.avenir-black();
		display: inline-block;
		vertical-align: middle;
		font-size: 16px;
		line-height: 1.19;
		padding-left: 15px;
	}
}

.card--theme.card--selectable {
	&:hover, &:focus, &:active {
		.card__sharing {
			opacity: 1;
			transform: translateX(0);
			-webkit-transform: translateX(0);
			transition: transform .15s, opacity .5s;
			-webkit-transition: -webkit-transform .15s, opacity .5s;
		}

		.card__title-after {
			width: 100px;
		}
	}

	.card__title {
		&:after {
			display: none;
		}
	}

	.card__title-after {
		width: 50px;
		height: 4px;
		position: absolute;
		bottom: 0;
		left: 0;
		transition: width .15s;
	}

	.icon-check {
		.square(54px);
		margin-left: -27px;
		margin-top: -27px;
	}
}

.card--theme.card--flipped {
	@media(min-width: @screen-sm-min) {
		.card__text {
			order: 2;
			-webkit-order: 2;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		.card__visual {
			order: 1;
			-webkit-order: 1;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}
	}
}

// Project card
// -------------------------

.card--project {
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	//width: 332px;
	height: 96px;

	&:hover, &:focus, &:active {
		.card__overlay {
			background-color: rgba(0, 0, 0, .25);
		}
	}

	.card__content {
		background: #fff;
		border-radius: 8px;
		height: 100%;
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		position: relative;
		display: table;
		width: 100%;
	}

	.card__text {
		padding: 5px 33px;
		width: 236px;
		height: 100%;
		background: #fff;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		display: table-cell;
		vertical-align: middle;
	}

	.card__visual {
		width: 96px;
		height: 96px;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		overflow: hidden;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		display: table-cell;
	}

	.card__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0);
		transition: background-color .25s;
		height: 96px;
	}

	.card__title {
		padding-bottom: 10px;
		margin-bottom: 0;
		font-size: 19px;
		line-height: 1.32;

		a {
			color: @text-color;
			display: block;
		}

		&:after {
			// content: '';
			// width: 40px;
			// height: 4px;
			// background-color: @ecstasy;
			// position: absolute;
			// bottom: 0;
			// left: 0;
			// transition: width .15s;
			display: none;
		}
	}

	.card__title-after {
		width: 40px;
		height: 4px;
		position: absolute;
		bottom: 0;
		left: 0;
		transition: width .15s;
	}
}

.card--project.card--selectable {
	&:hover, &:focus, &:active {
		.card__title-after {
			width: 70px;
		}
	}

	.card__selected-overlay {
		background-color: @ecstasy;
	}

	.icon-check {
		.square(34px);
		margin-left: -17px;
		margin-top: -17px;
	}
}